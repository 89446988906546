let data = {
  navpath: [
    {
      name: "Home",
      path: "/",
      sub: [],
    },
    {
      name: "Our Service",
      path: "",
      sub: [
        {
          name: "VPTI",
          path: "/vpti",
        },
        {
          name: "TKDN",
          path: "/tkdn",
        },
        {
          name: "LVV",
          path: "/lvv",
        },
        {
          name: "Other Services",
          path: "/our-services",
        },
      ],
    },
    {
      name: "Our Company",
      path: "/our-company",
      sub: [],
    },
    {
      name: "Contact Us",
      path: "/contact-us",
      sub: [],
    },
  ],
  values: [
    {
      key: 1,
      val: "Accuracy",
    },
    {
      key: 2,
      val: "Quality",
    },
    {
      key: 3,
      val: "Timely",
    },
  ],
  services: [
    {
      key: 1,
      name: "VPTI",
      img: "vpti",
      label: "VPTI",
      path: "/our-services",
    },
    {
      key: 2,
      name: "LCA",
      img: "tkdn",
      label: "LCA",
      path: "/our-services",
    },
    {
      key: 3,
      name: "VVB",
      img: "vvb",
      label: "VVB",
      path: "/our-services",
    },
    {
      key: 4,
      name: "Coal",
      img: "coal",
      label: "Coal",
      path: "/our-services",
    },
    {
      key: 5,
      name: "Marine",
      img: "marine",
      label: "Marine",
      path: "/our-services",
    },
    {
      key: 6,
      name: "Oil & Gas",
      img: "oil-and-gas",
      label: "Oil & Gas",
      path: "/our-services",
    },
    {
      key: 7,
      name: "Agriculture & Food",
      img: "agri",
      label: "Agriculture & Food",
      path: "/our-services",
    },
    {
      key: 8,
      name: "Mineral & Water",
      img: "mineral",
      label: "Mineral & Water",
      path: "/our-services",
    },
    {
      key: 9,
      name: "Non-Metallic Minerals",
      img: "non-metallic",
      label: "Non-Metallic Minerals",
      path: "/our-services",
    },
  ],
  branch:
    "ANINDYA has branch offices and laboratories located in many strategic locations throughout Indonesia. In serving clients from abroad, ANINDYA has also opened offices in several countries, that includes China, India, Singapore, Bangladesh and Vietnam.",
  clients: [
    {
      name: "VPTI",
      index: "0",
      img: [
        "Sharp",
        "The North Face",
        "Matsuo",
        "Mercedes",
        "Komatsu",
        "Starbucks",
        "Bridgestone",
        "Apple",
        "Samsung",
        "LV",
        "Brompton",
        "Aik Moh",
        "Uniqlo",
        "Hermes",
        "Canon",
        "Hp",
        "Panasonic",
        "Cold Stone",
        "ELC",
        "Kohler",
      ],
    },
    {
      name: "Coal",
      index: "1",
      img: [
        "Borneo Indobara",
        "Kideco",
        "Jembayan",
        "Adani",
        "Tamtama",
        "SGE",
        "BSSR",
        "BRE",
        "RLK",
        "Equentia",
        "PKN",
        "ABP",
        "BMB",
        "KJB",
        "Titan",
      ],
    },
    {
      name: "Marine",
      index: "2",
      img: [
        "SIT",
        "AKR",
        "Alexindo",
        "Duta Marine",
        "MSD",
        "Holcim",
        "Indocement",
        "Lafarge",
        "Victoria",
        "Seapol",
        "SLM",
        "SBA",
        "SBI",
        "Trubaindo",
        "HTK",
        "Cotecna",
        "DBS",
        "Watama",
        "Figar",
      ],
    },
    {
      name: "Oil & Gas",
      index: "3",
      img: ["DCG", "BNP", "PC", "RBN", "DSI", "ABS", "FJS"],
    },
    {
      name: "Mineral & Water",
      index: "4",
      img: [
        "VDNI",
        "SMI",
        "BSI",
        "YNI",
        "YII",
        "YII-2",
        "GAG",
        "Ranger",
        "Walsin",
        "Hengjaya",
        "TSI",
        "Ifishco",
        "CLM",
      ],
    },
    {
      name: "TKDN",
      index: "5",
      img: [
        "1. PT. LG Electronics Indonesia",
        "2. PT. Castrol manufacturing indonesia",
        "3. PT. Panasonic Manufacturing Indonesia",
        "4. PT. Daikin Manufacturing Indonesia",
        "5. PT. Pralon",
        "6. PT. Xiaomi Technology Indonesia",
        "7. PT. Industri Pipa Indonesia",
        "8. PT. Gunung Raja Paksi",
        "9. PT. KMI Wire and Cable",
        "10. PT. Onda Mega Industri",
        "11. PT Holi Pharma - HD Removed BG",
        "12 - PT KONIMEX - Logo",
        "13. PT. Communication Cable Systems Indonesia",
        "14. PT. Sumi Indo Kabel, Tbk",
        "15. PT. Kurnia Abadi Padang",
        "16. PT. Cendo",
        "17. PT. Symphos Electric",
        "18. PT. Bevananda Mustika",
        "19. PT. Moradon Berlian Sakti",
        "20. PT. Selamat sempurna",
        "21. PT. Muliaglass",
        "22. PT. Astajaya Nirwighnata",
        "23. PT. Tata Metal Lestari",
        "24. PT Maxima Daya Indonesia",
        "25. PT. Acer Manufacturing Indonesia",
        "26. PT 3S International",
        "27. PT. Meprofarm",
        "28. PT Bolde",
        "29. PT. Asus Technology Indonesia Batam",
        "30. PT Pura Mayungan",
        "31. PT. Hartono Istana Teknologi",
        "32. PT. Roda Pasifik Mandiri",
        "33. PT. Astra Komponen Indonesia",
      ],
    },
  ],
  our_company:
    "One stop authorized mining-related trading certification & verification services",
  about_company:
    "ANINDYA, initially established in Bandung City in August 1982, is an independent surveying company, providing quality and quantity superintending service for coal and minerals industry, the oil and gas sector as well as marine surveys. ANINDYA also received permission from the Directorate General of Foreign Trade, Ministry of Trade of the Republic of Indonesia to be able to verify imported products for commodities such as iron & steel, food & drinks, traditional medicine, toys, footwear, garment & textiles, and electronics. ANINDYA has branch offices and laboratories located in many strategic locations throughout Indonesia. In serving clients from abroad, ANINDYA has also opened offices in several countries, that includes China, India, Singapore, Bangladesh and Vietnam.",
  vision_company:
    "Confident to become one of the greatest independents surveying company in Indonesia who is able deliver superb service to our customer.",
  mission_company:
    "Ensure our consumers are satisfied with our superb services in an accurate, professional and timely manner. Provide quality and quantity superintending services for coal-related industry, minerals, marine survey as well as oil and gas sectors with a professional working culture supported by highly competent resources with high integrity attitude.",
  branches: [
    {
      location: "Bandung",
      type: "Head Office",
      address: [
        {
          name: "JI. Buah Batu No. 43 Lt. 2",
          district: "Kel. Malabar, Kec. Lengkong, Bandung, Jawa Barat 40262",
          province: "(+62 22) 87346430",
          phone: "",
        },
      ],
    },
    {
      location: "Banjarbaru",
      type: "Office",
      address: [
        {
          name: "Jl. Ahmad Yani KM 22.7 RT 02/RW 01",
          district:
            "Kel. Landasan Ulin Tengah, Kec. Liang Anggang, Banjarbaru, Kalimantan Selatan 70722",
          province: "",
          phone: "",
        },
      ],
    },
    {
      location: "Banjarbaru",
      type: "Laboratory",
      address: [
        {
          name: "Jl. Ahmad Yani KM 21 RT 02/RW 02",
          district:
            "Kel. Landasan Ulin Tengah, Kec. Liang Anggang, Banjarbaru, Kalimantan Selatan 70722",
          province: "(+62 511)4706093",
          phone: "",
        },
      ],
    },
    {
      location: "Bara Tabang",
      type: "Office",
      address: [
        {
          name: "Senyiur KM 00 Jetty PT Bayan Resources Tbk",
          district:
            "Desa Senyiur, Kec. Muara Ancalong, Kab. Kutai Timur, Kalimantan Timur 75656",
          province: "",
          phone: "",
        },
      ],
    },
    {
      location: "Batam",
      type: "Office",
      address: [
        {
          name: "Taman Golf Residence II Blok R1 No. 32",
          district:
            "Kel. Sukajadi, Kec. Batam Kota, Batam, Kepulauan Riau 29444",
          province: "",
          phone: "",
        },
      ],
    },
    {
      location: "Batulicin",
      type: "Office & Laboratory",
      address: [
        {
          name: "JI. Transmigrasi KM 3,5",
          district:
            "Kel. Barokah, Kec. Simpang Empat Batulicin, Kab. Tanah Bumbu, Kalimantan Selatan 72213",
          province: "(+62 518) 75638",
          phone: "",
        },
      ],
    },
    {
      location: "Berau",
      type: "Office & Laboratory",
      address: [
        {
          name: "JI. H.A.R.M Ayoeb RT 13 Gg. Rahmad No. 2",
          district:
            "Kel. Gunung Tabur, Kec. Gunung Tabur, Kab. Berau, Kalimantan Timur 77352",
          province: "",
          phone: "",
        },
      ],
    },
    {
      location: "Cikarang",
      type: "Office & Laboratory",
      address: [
        {
          name: "Kawasan Industri Delta Silikon 3 Lippo Cikarang",
          district: "Jl. Rotan Blok F 27 No. 23 C",
          province:
            "Kel. Cicau, Kec. Cikarang Pusat, Kab. Bekasi, Jawa Barat 17531",
          phone: "",
        },
      ],
    },
    {
      location: "Jakarta",
      type: "Headquarter",
      address: [
        {
          name: "Springhill Office Tower 17 th & 18th Floor",
          district:
            "Jl. H. Benyamin Suaeb Ruas D7  Block D6, Pademangan Timur, Jakarta Utara 14410",
          province: "(+62 21) 2260 6207",
          phone: "",
        },
      ],
    },
    {
      location: "Jambi",
      type: "Office & Laboratory",
      address: [
        {
          name: "JI. Abdul Rahman Saleh No. 8",
          district: "Kel. The Hok, Kec. Jambi Selatan, Jambi 36138",
          province: "(+62 741) 572805",
          phone: "",
        },
      ],
    },
    {
      location: "Kendari",
      type: "Office & Laboratory",
      address: [
        {
          name: "JI. Poros Bandara Haluoleo",
          district:
            "Desa Onewila, Kec. Ranomeeto Kab. Konawe Selatan, Sulawesi Tenggara 93372",
          province: "",
          phone: "",
        },
      ],
    },
    {
      location: "Palembang",
      type: "Office & Laboratory",
      address: [
        {
          name: "Komp. Pergudangan Sukarame Blok D No. 9",
          district: "JI. Tembus Terminal",
          province:
            "Kel Talang Kelapa, Kec. Alang-Alang Lebar, Palembang, Sumatera Selatan 30151",
          phone: "",
        },
      ],
    },
    {
      location: "Pontianak",
      type: "Office & Laboratory",
      address: [
        {
          name: "JI. Arteri Supadio Gg. Anyar RT 07 RW 10",
          district:
            "Desa Arang Limbung, Kec. Sungai Raya, Pontianak, Kalimantan Barat 78391",
          province: "",
          phone: "",
        },
      ],
    },
    {
      location: "Samarinda",
      type: "Office & Laboratory",
      address: [
        {
          name: "JI. KH. Harun Nafsi No. 56",
          district:
            "Kel. Rapak Dalam, Kec. Loa Janan llir, Samarinda, Kalimantan Timur 75131",
          province: "(+62 541) 7269686, Fax: (+62 541) 7269705",
          phone: "",
        },
      ],
    },
    {
      location: "Tamiang Layang",
      type: "Office",
      address: [
        {
          name: "JI. Tumpa Dayu RT 11",
          district:
            "Desa Tamiang Layang, Kec. Dusun Timur, Kab. Barito Timur, Kalimantan Tengah 73617",
          province: "(+62 526) 2091361",
          phone: "",
        },
      ],
    },
    {
      location: "Tarakan",
      type: "Office",
      address: [
        {
          name: "JI. Flamboyan No. 57 RT 27",
          district:
            "Kel. Karang Anyar, Kec. Tarakan Barat, Tarakan, Kalimantan Utara 77111",
          province: "(+62 511) 25210",
          phone: "",
        },
      ],
    },
  ],
  licenses: "Licenses issued by Republic of Indonesia",
  licenses_desc:
    "ANINDYA is in compliance with government regulations by obtaining several licenses.",
  licenses_tab1: {
    name: "Import Commodities Verification (Laporan Surveyor)",
    title: "MINISTRY OF TRADE REPUBLIC OF INDONESIA",
    val: [
      "Certain Products: No. 782/2019",
      "Steel, Iron, Steel Alloy & Steel Derivatives: No. 781/2019",
      "Tires: No. 365/2020",
      "Cell Phones, Handheld Computers and Tablet Computers: No. 366/2020",
      "Color Multipurpose Machines, Color Photocopy Machines, and Color Printers: No. 367/2020",
      "Cooling System Based Goods: NO. 421/2020",
      "Footwear, Electronics, Bicycles & Tricycles: No. 1067/2020",
      "Secondhand Capital Goods: No. 1087/2020",
      "Ceramics: No. 778/2021",
      "Horticulture: No. 779/2021",
      "Precursor: No. 780/2021",
      "Apparel and Apparel Accesories: No. 1491/2022",
      "Garlic: No. 1492/2022",
      "Downstream Product of Plastic: No. 463/2023",
      "Glass Sheet: No. 1241/2023",
      "Textile and Textile Product (TPT): No. 145/2024",
      "Footwear: No. 353/2024",
      "Electronic: No. 354/2024",
      "Bicycles and Tricycles: No.355/2024",
      "Food and Beverage: No. 356/2024",
      "Children’s Toys: No. 358/2024",
      "Cosmetics and Households Goods: No. 358/2024",
      "Traditional Medicine and Health Suplements: No. 359/2024",
      "Textile Product: No. 360/2024",
      "Bag: No. 464/2024",
    ],
    title2: "",
    val2: [],
  },
  licenses_tab2: {
    name: "Quality Analysis and Quantity Verification for Coal Trade:",
    title: "DIRECTORATE GENERAL OF MINERAL AND COAL - ESDM",
    val: ["No. 1029.K/30/DJB/2014"],
    title2: "",
    val2: [],
  },
  licenses_tab3: {
    name: "Export Verification (Laporan Surveyor)",
    title: "MINISTRY OF TRADE REPUBLIC OF INDONESIA",
    val: ["Coal: No. 780/M-DAG/KEP/6/2017", "Mineral: No. 558/2020"],
    title2: "",
    val2: [],
  },
  licenses_tab4: {
    name: "Local Content Assessment (TKDN)",
    title: "MINISTRY OF INDUSTRY REPUBLIC OF INDONESIA",
    val: ["No. 4058/2023"],
    title2: "DIREKTORAT JENDERAL KETENAGALISTRIKAN - ESDM",
    val2: [
      "Electricity Distribution: No. 0013/1/08/2024",
      "Electricity Transmission: No. 0024/2/08/2024",
      "Electricity Generation: No. 0015/1/08/2024",
    ],
  },
  certification: "Accreditation & Certification",
  certification_desc:
    "ANINDYA has passed and obtained the accreditation of laboratory testing and calibration for inspection by KAN (National Accreditation Committee), as well as the certificate of quality management system, environment, health and safety management system by Bureau Veritas.",
  certification_tab1: {
    name: "KAN (Komite Akreditasi Nasional)",
    desc: [
      {
        no: "SNIISO/IEC 17020: 2012",
        detail: "Accreditation for Inspection Body No. LI-084-IDN",
      },
      {
        no: "SNI ISO/IEC 17025: 2017",
        detail: "Accreditation for Testing Laboratory ",
      },
    ],
    val: [
      "No. LP-844-IDN PT Anindya Wiraputra Konsult Banjarbaru",
      "No. LP-882-IDN PT Anindya Wiraputra Konsult Samarinda",
      "No. LP-955-IDN PT Anindya Wiraputra Konsult Baratabang",
      "No. LP-1328-IDN PT Anindya Wiraputra Konsult Palembang",
      "No. LP-1357-IDN PT Anindya Wiraputra Konsult Kendari",
      "No. LP-1420-IDN PT Anindya Wiraputra Konsult Berau",
      "No. LP-1421-IDN PT Anindya Wiraputra Konsult Cikarang",
      "No. LP-1468-IDN PT Anindya Wiraputra Konsult Pontianak",
    ],
  },
  certification_tab2: {
    name: "Bureau Veritas",
    desc: [
      {
        no: "ISO 9001:2015",
        detail: "Certification for Quality Management Systems: No. ID003510",
        detail2: "",
      },
      {
        no: "ISO 14001: 2015",
        detail:
          "Certification for Environmental Management Systems: No. ID003511",
        detail2: "",
      },
      {
        no: "ISO 45001: 2018",
        detail: "Certification for Occupational Health and Safety Management",
        detail2: "Systems: No. ID003741",
      },
    ],
    val: [],
  },
  certification_tab3: {
    name: "Tuv Rheinland Certified",
    desc: [
      {
        no: "SNI ISO/IEC 27001:2013",
        detail:
          "Certification for Information Security of Survey Application Report No. 82415321031",
        detail2: "",
      },
      {
        no: "SNI ISO/IEC 37001:2016",
        detail:
          "Certification for Anti-Bribery Management System No. ID8243162206",
        detail2: "",
      },
      {
        no: "ISO 45001: 2018",
        detail: "Certification for Occupational Health and Safety Management",
        detail2: "Systems: No. ID003741",
      },
    ],
    val: [],
  },

  vpti: "Indonesia’s Ministry of Trade has mandated that a wide range of goods be inspected prior to export to the country to demonstrate compliance with various national regulations. To meet the Indonesian market requirements our technical verification services identify the eligibility of the goods to be imported into Indonesia as well as the quantity, tariff classification and Ministry of Trade specifications. The issuance of the ANINDYA report known as a Laporan Surveyor or Surveyor’s Report facilitates international trade, reduces the risk of delays in Customs clearance and reduces potential losses from the import of non-compliant goods.",
  flow_vpti:
    "Indonesia’s Ministry of Trade has mandated that a wide range of goods be inspected prior to export to the country to demonstrate compliance with various national regulations. To meet the Indonesian market requirements our technical verification services identify the eligibility of the goods to be imported into Indonesia as well as the quantity, tariff classification and Ministry of Trade specifications. The issuance of the ANINDYA report known as a Laporan Surveyor or Surveyor’s Report facilitates international trade, reduces the risk of delays in Customs clearance and reduces potential losses from the import of non-compliant goods.",
  import_vpti_dasar_hukum_fixed: [
    {
      main: "Regulation of the Director General of Foreign Trade (Perdirjen) No. 13/Daglu/Per/05/2024 Concerning Verification or Technical Investigation of Imports for Certain Goods",
      url: "https://drive.google.com/file/d/1rGkHc6HBGklF8-upa5jPt6yIpxxPwuV7/view?usp=sharing",
      rev: [],
    },
    {
      main: "Regulation of the Minister of Trade of the Republic of Indonesia Number 8 of 2024 on the Third Amendment to the Minister of Trade Regulation Number 36 of 2023 on Import Policies and Regulations",
      url: "https://drive.google.com/file/d/1fiw32GHM3g8AHrXK5cgOOArDscWArYwL/view?usp=sharing",
      rev: [],
    },
  ],
  import_vpti: [
    {
      tab_title: "FOOTWEAR",
      pic: "alas-kaki",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021",
              url: "https://drive.google.com/file/d/1Q7_nQMzqbRzyuygScu4pIJ0lW5uJXoR0/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2020 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1MS7sSnMARqx5qbwEnaRDDXmOZ-PksFQz/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 16 of the Year 2021",
              url: "https://drive.google.com/file/d/1uym_4R1K-cW25M_fJQmCkBhJa_5VEgt6/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products.",
              url: "https://drive.google.com/file/d/1GlLNdFwDHVvIlY-9CVj0HBvBj7N9F8VR/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 28 of the Year 2020 concerning the Eighth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1JWCPymLibiK72u7tzyg3gN8llytWXMI0/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 24 of the Year 2019 concerning the Seventh Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1Wa66lbZnpuBrmStAy-pf4pao3Dj-pADF/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 121 of the Year 2018 concerning the Sixth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1PyaTiSPoFZrj1JRmGC43iI8In_pG_ZjM/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 42 of the Year 2018 concerning the Fifth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1bQ-LTq8QaLEQry2D0DCOZ-30CGPsTa3N/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 12 of the Year 2018 concerning the Fourth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1LT5jW3R1CNXaES45UUiSD-TyoMbTAOGF/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 94 of the Year 2017 concerning the Third Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1HkEDhBMj6Lg7FppOR06J6uJctIQDo4NI/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 81 of the Year 2017 concerning the Second Amendment to the Regulation of the Minister of Trade (Permendag) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1rUBYQFKDKJHyvKXENybVbcYdl5feHDts/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 94/M-DAG/PER/10/2015 concerning Amendments to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1rUvUxyVExAo9dzYgSOnAMhWy1EFiMjLn/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 68 of the Year 2020 concerning Provisions for the Import of Footwear, Electronics, and Two-Wheel and Three-Wheel Bikes.",
              url: "https://drive.google.com/file/d/1Yr3SltRGlXUBDkBnmTZr2FXXynUXZQXM/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 78 of the Year 2020 concerning Amendments to Regulation of the Minister of Trade (PERMENDAG) Number 68 of the Year 2020 concerning Provisions for the Import of Footwear, Electronics, and Two-Wheel and Three-Wheel Bikes.",
                  url: "https://drive.google.com/file/d/1o47ZpzBwRlLKS10Ry9Q6CwUJi4DX4ciD/preview",
                },
              ],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Letter of Appointment to PT Anindya Wiraputra Kosult for Footwear Products",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 782 of the Year 2019 concerning Delegation of Surveyors to execute Verification or Technical Investigation for the Import of Certain Products.",
              url: "https://drive.google.com/file/d/1s0qJqjcKlo08tiSX3QWJxOs5uHeO0l7e/preview",
              rev: [],
            },
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 1067 of the Year 2020 concerning the Appointment of PT Anindya Wiraputra Konsult (Persero) as a Surveyor to Execute Verification or Technical Investigation for the Import of Footwear, Electronics, and Two-Wheel and Three-Wheel Bikes.",
              url: "https://drive.google.com/file/d/1j54C2Cw48yguhx_UU9fhaY--8mbLGgQp/preview",
              rev: [],
            },
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 353 of the Year 2024 concerning the Appointment of PT Anindya Wiraputra Konsult (Persero) as a Surveyor to Execute Verification or Technical Investigation for the Import of Footwear",
              url: "https://drive.google.com/file/d/1Ds5EXED3KSmVRS_nNGsRtS_4ud9jsNSo/view?usp=sharing",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            { title: "Country of origin and port of loading", val: [] },
            {
              title: "Description of goods and Tariff Post / HS code",
              val: [],
            },
            {
              title:
                "Product Certification Number Using National Standard Mark (SPPT SNI), for certain required products",
              val: [],
            },
            { title: "Goods Registration Number (NPB)", val: [] },
            { title: "Shipping time", val: [] },
            { title: "Destination port", val: [] },
          ],
        },
      ],
      catatan: [
        {
          title: "Notes",
          text: "Regulations on the Limit and Restrictions (Lartas) for Footwear Products are separated into 2 (two) categories, under the Regulation of the Minister of Trade (PERMENDAG) Number 28 of the Year 2020 and Number 78 of the Year 2020.",
        },
      ],
      kontak: [
        {
          title: "Contact Us",
          nama: "Gunawan Pragusty Indrabuana",
          email: "gunawan_lsi@anindya.biz",
          telp: "+62 812-2392-6667",
        },
        {
          title: "",
          nama: "Victoria Jesslyn Hong",
          email: "victoria_lsi@anindya.biz",
          telp: "+62 851-7117-2891",
        },
      ],
      clients: [
        "Hush Puppies",
        "Under Armour",
        "Columbia",
        "Fitflop",
        "Vans",
        "The North Face",
        "Salomon",
      ],
    },
    {
      tab_title: "TIRE",
      pic: "ban",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021",
              url: "https://drive.google.com/file/d/1Q7_nQMzqbRzyuygScu4pIJ0lW5uJXoR0/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2020 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1MS7sSnMARqx5qbwEnaRDDXmOZ-PksFQz/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 16 of the Year 2021",
              url: "https://drive.google.com/file/d/1uym_4R1K-cW25M_fJQmCkBhJa_5VEgt6/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 77/M-DAG/PER/11/2016 concerning Provisions for the Import of Tires.",
              url: "https://drive.google.com/file/d/1MFJHiu6pYwxOaawIZKC4Kz9fYhioTfa_/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 05 of the Year 2019 concerning the Third Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 77/M-DAG/PER/11/2016 concerning Provisions for the Import of Tires;",
                  url: "https://drive.google.com/file/d/1dXJBlFcyt96jYKRy2ovU7MKerZ8fht51/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (Permendag) Number 117 of the Year 2018 concerning the Second Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 77/M-DAG/PER/11/2016 concerning Provisions for the Import of Tires;",
                  url: "https://drive.google.com/file/d/1Q05WH85bz7-2C8U9wbsjBN2_epgS2t-J/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 06 of the Year 2018 concerning Amendments to the Regulation of the Minister of Trade (PERMENDAG) Number 77/M-DAG/PER/11/2016 concerning Provisions for the Import of Tires;",
                  url: "https://drive.google.com/file/d/1WvDrdCEnFubRDEuk4HXwltYgpjmyzUk2/preview",
                },
              ],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Letter of Appointment to PT Anindya Wiraputra Kosult for Tires",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 365 of the Year 2020 concerning the Delegation of Surveyors to Execute Verification or Technical Investigation for the Import of Tires.",
              url: "https://drive.google.com/file/d/1Bf-4Hn-LjJQS55kn0JCzBsxFM4cywyGr/preview",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            { title: "Country of origin and port of loading", val: [] },
            { title: "Tariff Post or HS code and description", val: [] },
            { title: "Brand and quantity", val: [] },
            { title: "Type and size", val: [] },
            { title: "Shipping time", val: [] },
            { title: "Destination port", val: [] },
            {
              title:
                "Propriety of Product Certification Number Using Indonesian National Standard Mark (SPPT SNI) for Tires with documents from origin",
              val: [],
            },
          ],
        },
      ],
      catatan: [
        {
          title: "Notes",
          text: "Import Approval from the Minister of Trade (PI) expiration date differs for API-P (valid for 1 year) and API-U (valid for 6 months).",
        },
      ],
      kontak: [
        {
          title: "Contact Us",
          nama: "Zulkifli Heru Susanto",
          email: "zulkifli.heru@anindya.biz",
          telp: "+62 856-9242-7289",
        },
      ],
      clients: [
        "Continental",
        "Goodyear",
        "Bridgestone",
        "Yokohama",
        "Pirelli",
        "Longmarch",
        "Maxxis",
        "Toyo Tires",
        "Triangle",
      ],
    },
    {
      tab_title: "COOLING SYSTEM BASED GOODS",
      pic: "barang",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021",
              url: "https://drive.google.com/file/d/1Q7_nQMzqbRzyuygScu4pIJ0lW5uJXoR0/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2020 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1MS7sSnMARqx5qbwEnaRDDXmOZ-PksFQz/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 16 of the Year 2021",
              url: "https://drive.google.com/file/d/1uym_4R1K-cW25M_fJQmCkBhJa_5VEgt6/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 84/M-DAG/PER/10/2015 concerning Provisions on Import of Cooling System Based Goods.",
              url: "https://drive.google.com/file/d/1yToXFoC5SvldCUoKlUShxyFbOovoGAwJ/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 18 of the Year 2018 concerning Amendments to Regulation of the Minister of Trade (PERMENDAG) Number 84/M-DAG/PER/10/2015 concerning Provisions on Import of Cooling System Based Goods;",
                  url: "https://drive.google.com/file/d/1NYnasaTngPeU2DOZtLKFCo0rqRYEhdB7/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 40 of the Year 2016 concerning Amendments to Regulation of the Minister of Trade (PERMENDAG) Number 84/M-DAG/PER/10/2015 concerning Provisions on Import of Cooling System Based Goods.",
                  url: "https://drive.google.com/file/d/1rie_I05XGbkVu6OJplaqjoEQ35zwT_Om/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 68 of the Year 2020 concerning Provisions for the Import of Footwear, Electronics, and Two-Wheel and Three-Wheel Bikes.",
              url: "https://drive.google.com/file/d/1Yr3SltRGlXUBDkBnmTZr2FXXynUXZQXM/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 78 of the Year 2020 concerning Amendments to Regulation of the Minister of Trade (PERMENDAG) Number 68 of the Year 2020 concerning Provisions for the Import of Footwear, Electronics, and Two-Wheel and Three-Wheel Bikes.",
                  url: "https://drive.google.com/file/d/1o47ZpzBwRlLKS10Ry9Q6CwUJi4DX4ciD/preview",
                },
              ],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Letter of Appointment of PT Anindya Wiraputra Kosult for Cooling System Based Goods",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 421 of the Year 2020 concerning Delegation of Surveyors to Execute Verification or Technical Investigation of Imported Cooling System Based Goods.",
              url: "https://drive.google.com/file/d/1hb0Tl2sa1hgs1E_XYyTVSzP33H7oWogR/preview",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            { title: "Name and address of importer and exporter", val: [] },
            {
              title:
                "Type, Tariff Post / HS code 10 (ten) digits and their description",
              val: [],
            },
            { title: "Specification of refrigerants used", val: [] },
            { title: "Amount, volume, or weight", val: [] },
            { title: "Country of origin", val: [] },
            { title: "Country and port of loading", val: [] },
            { title: "Destination port", val: [] },
          ],
        },
      ],
      catatan: [
        {
          title: "Notes",
          text: "Non-New Capital Goods that are included in Cooling System Based Goods in the Minister of Trade Regulation 75/2013 are subject to the Provisions for the Import of Cooling System Based Goods. For Refrigerant System Based Goods, also pay attention to the provisions in the Minister of Trade Regulation Number. 84/2015 and Number 68/2020.",
        },
      ],
      kontak: [
        {
          title: "Contact Us",
          nama: "Silvia Wulandary",
          email: "silvia.wulandary_lsi@anindya.biz",
          telp: "+62 822-1666-4542",
        },
        {
          title: "",
          nama: "Teddy",
          email: "teddy_lsi@anindya.biz",
          telp: "+62 813-2237-3773",
        },
      ],
      clients: [
        "Ace",
        "Daikin",
        "Hisense",
        "Modena",
        "Panasonic",
        "Krisbow",
        "LG",
        "Tica",
      ],
    },
    {
      tab_title: "SECOND HAND CAPITAL GOODS",
      pic: "barang-modal-tidak-baru",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021",
              url: "https://drive.google.com/file/d/1Q7_nQMzqbRzyuygScu4pIJ0lW5uJXoR0/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2020 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1MS7sSnMARqx5qbwEnaRDDXmOZ-PksFQz/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 16 of the Year 2021",
              url: "https://drive.google.com/file/d/1uym_4R1K-cW25M_fJQmCkBhJa_5VEgt6/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 118 of 2018 concerning Provisions on the Import of Second Hand Capital Goods.",
              url: "https://drive.google.com/file/d/1i2k8ZDE0LM6VrHLUVvRRRlo_ZT-NYPOZ/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (Permendag) Number 76 of 2019 concerning Amendments to Regulation of the Minister of Trade Number 118 of 2018 concerning Provisions on the Import of Capital Goods in Non-New Conditions.",
                  url: "https://drive.google.com/file/d/1-kC4DQA5yDfM9QxKz-v9f4pMSx2t_4lA/preview",
                },
              ],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Letter of Appointment of PT Anindya Wiraputra Kosult for Second Hand Capital Goods",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 1087 of the Year 2020 concerning Delegation of Surveyors to Execute Verification or Technical Investigation of Imported Capital Goods in Non-New Conditions.",
              url: "https://drive.google.com/file/d/1FbVwYtH_Vk-Z9ldItnJMrPlE3my4FBwn/preview",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            {
              title: "Usability: suitable for use, repair, and/or re-operation",
              val: [],
            },
            { title: "HS Code", val: [] },
            { title: "Age for goods which import age limit is set", val: [] },
            { title: "Amount and value", val: [] },
          ],
        },
      ],
      catatan: [],
      kontak: [
        {
          title: "Contact Us",
          nama: "Aditya Tohir",
          email: "aditya.wijaya_lsi@anindya.biz",
          telp: "+62 822-1365-4798",
        },
      ],
      clients: ["Akebono", "BAP", "Cimory", "Matsuo", "IMCP", "Texcoms"],
    },
    {
      tab_title: "OTHER FINISHED TEXTILE GOODS",
      pic: "barang-tekstil-sudah-jadi-lainnya",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade of the Republic of Indonesia Number 36 of 2023 on Import Policies and Regulations",
              url: "https://drive.google.com/file/d/1ZHuRVhYRFDlJWRqqQ4NayId5kOYrRrJx/view?usp=sharing",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade of the Republic of Indonesia Number 8 of 2024 on the Third Amendment to the Minister of Trade Regulation Number 36 of 2023 on Import Policies and Regulations",
              url: "https://drive.google.com/file/d/1fiw32GHM3g8AHrXK5cgOOArDscWArYwL/view?usp=sharing",
              rev: [],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Letter of Appointment for PT Anindya Wiraputra Konsult for Other Finished Textile Goods",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade (Kepmendag) Number 360 of 2024 on the Appointment of PT Anindya Wiraputra Konsult as the Implementing Surveyor for the Verification or Technical Tracing of Other Finished Textile Goods Import",
              url: "https://drive.google.com/file/d/1aQrueQxEkHLnvKRNslYfuPa63PoAUUNw/view?usp=drivesdk",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            {
              title:
                "Other Finished Textile Goods imported must be in new condition",
              val: [],
            },
            {
              title:
                "Finished Textile Goods that are required to meet SNI (Indonesian National Standard) requirements",
              val: [],
            },
            {
              title:
                "Other Finished Textile Goods must provide product information, whether in the form of labels, catalogs, or other forms, consisting of:",
              val: [
                "Product name",
                "Product specifications; and",
                "Country of origin.",
              ],
            },
          ],
        },
      ],
      catatan: [],
      kontak: [
        {
          title: "Contact Us",
          nama: "Gunawan Pragusty Indrabuana",
          email: "gunawan_lsi@anindya.biz",
          telp: "+62 812-2392-6667",
        },
        {
          title: "",
          nama: "Victoria Jesslyn Hong",
          email: "victoria_lsi@anindya.biz",
          telp: "+62 851-7117-2891",
        },
      ],
      clients: [],
    },
    {
      tab_title: "GARLIC",
      pic: "bawang-putih",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Peraturan Menteri Perdagangan Republik Indonesia Nomor 20 Tahun 2021 Tentang Kebijakan dan Pengaturan Impor",
              url: "https://drive.google.com/file/d/1xG56RUzkaT7LTu1_IqprxSZrtiehm_Gm/preview",
              rev: [],
            },
            {
              main: "Peraturan Menteri Perdagangan Republik Indonesia Nomor 16 Tahun 2021 Tentang Verifikasi atau Penelusuran Teknis dI Bidang Perdagangan Luar Negeri",
              url: "https://drive.google.com/file/d/1QlQ8aqKmiJl-4cpExX-B27HRtQ9VBug_/preview",
              rev: [],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Letter of Appointment of PT Anindya Wiraputra Kosult for Garlic",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade of the Republic of Indonesia Number 1492 of 2022 concerning the Appointment of PT Anindya Wiraputra Konsult as Surveyor Implementing Verification or Technical Investigation of Garlic Imports",
              url: "https://drive.google.com/file/d/1tadweWolvLzPVnHfY_qs9aD8py-zodfO/preview",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            { title: "Verification order number;", val: [] },
            { title: "Surveyor Report Number;", val: [] },
            { title: "Importer's name and address;", val: [] },
            { title: "Exporter's name and address;", val: [] },
            { title: "Country of origin;", val: [] },
            { title: "Loading port;", val: [] },
            { title: "Destination port;", val: [] },
            { title: "Garlic Import Approval Number;", val: [] },
            {
              title:
                "The item description includes the Harmonized System (HS) code;",
              val: [],
            },
            { title: "Number/volume and units of goods;", val: [] },
            {
              title:
                "Place and date of Verification or Technical Investigation;",
              val: [],
            },
            { title: "Modes of transportation;", val: [] },
            { title: "Container number;", val: [] },
            { title: "Surveyor's seal number;", val: [] },
            { title: "Other information required.", val: [] },
          ],
        },
      ],
      catatan: [],
      kontak: [
        {
          title: "Contact Us",
          nama: "Almira Nurdiana",
          email: "almira.nurdiana_lsi@anindya.biz",
          telp: "+62 812-1325-2190",
        },
        {
          title: "",
          nama: "Stefanus Pradoddy",
          email: "stefanus_lsi@anindya.biz",
          telp: "+62 815-6003-190",
        },
      ],
      clients: ["Dua kelinci", "Haniori"],
    },
    {
      tab_title: "STEEL IRON, STEEL ALLOY, & STEEL DERIVATIVE PRODUCT",
      pic: "besi-baja",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021",
              url: "https://drive.google.com/file/d/1Q7_nQMzqbRzyuygScu4pIJ0lW5uJXoR0/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2020 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1MS7sSnMARqx5qbwEnaRDDXmOZ-PksFQz/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 16 of the Year 2021",
              url: "https://drive.google.com/file/d/1uym_4R1K-cW25M_fJQmCkBhJa_5VEgt6/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 110 of the Year 2018 concerning Provisions for the Import of Steel Iron, Steel Alloy, & Steel Derivatives.",
              url: "https://drive.google.com/file/d/1RxhrqMH_Li3wBMArHatEm7M8XXECWB2J/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 03 of the Year 2020 concerning Amendments to Regulation of the Minister of Trade (PERMENDAG) Number 110 of the Year 2018 concerning Provisions on the Import of Steel Iron, Steel Alloy, and Steel Derivative Products.",
                  url: "https://drive.google.com/file/d/1EsST9do-O-tYnxH-vEH8uXpLlBfc6RxA/preview",
                },
              ],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Appointment Letter of PT Anindya Wiraputra Kosult for Steel Iron, Steel Alloy, & Steel Derivative Products",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 781 of the Year 2019 concerning Delegation of Surveyors to Execute Verification or Technical Investigation for the Import of Steel Iron, Steel Alloy, and Steel Derivative Products",
              url: "https://drive.google.com/file/d/1u-MH0j3kR6N6jBnONuJn5G_RGgyuuhqm/preview",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            {
              title:
                "Data or information in the Import Approval (PI) from the Minister of Trade",
              val: [],
            },
            {
              title:
                "Conformity of imported Iron or Steel and Alloy Steel with mill test certificate (MTC)",
              val: [],
            },
            {
              title:
                "Product Certification Number Using National Standard Mark (SPPT SNI), for certain required products",
              val: [],
            },
          ],
        },
      ],
      catatan: [
        {
          title: "Notes",
          text: "There are 360 \u200bPost \u200bTariff Codes for Iron and Steel, 65 Post \u200bTariff Codes for Alloy Steel, and 55 Post \u200bTariff Codes for Steel Derivative Products.",
        },
      ],
      kontak: [
        {
          title: "Contact Us",
          nama: "Sinatrya Azali",
          email: "sinatrya.azali_lsi@anindya.biz",
          telp: "+62 811-2252-333",
        },
        {
          title: "",
          nama: "Chintya F",
          email: "Chintya.f_lsi@anindya.biz",
          telp: "+62 812-2259-9909",
        },
      ],
      clients: [
        "Komatsu",
        "Mercedes",
        "Bridon",
        "Sarana Steel",
        "Kobexindo",
        "Schlumberger",
        "Toyota Tsusho",
      ],
    },
    {
      tab_title: "ELECTRONIC",
      pic: "elektronika",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021",
              url: "https://drive.google.com/file/d/1Q7_nQMzqbRzyuygScu4pIJ0lW5uJXoR0/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2020 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1MS7sSnMARqx5qbwEnaRDDXmOZ-PksFQz/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 16 of the Year 2021",
              url: "https://drive.google.com/file/d/1uym_4R1K-cW25M_fJQmCkBhJa_5VEgt6/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products.",
              url: "https://drive.google.com/file/d/1GlLNdFwDHVvIlY-9CVj0HBvBj7N9F8VR/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 28 of the Year 2020 concerning the Eighth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1JWCPymLibiK72u7tzyg3gN8llytWXMI0/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 24 of the Year 2019 concerning the Seventh Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1Wa66lbZnpuBrmStAy-pf4pao3Dj-pADF/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 121 of the Year 2018 concerning the Sixth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1PyaTiSPoFZrj1JRmGC43iI8In_pG_ZjM/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 42 of the Year 2018 concerning the Fifth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1bQ-LTq8QaLEQry2D0DCOZ-30CGPsTa3N/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 12 of the Year 2018 concerning the Fourth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1LT5jW3R1CNXaES45UUiSD-TyoMbTAOGF/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 94 of the Year 2017 concerning the Third Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1HkEDhBMj6Lg7FppOR06J6uJctIQDo4NI/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 81 of the Year 2017 concerning the Second Amendment to the Regulation of the Minister of Trade (Permendag) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1rUBYQFKDKJHyvKXENybVbcYdl5feHDts/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 94/M-DAG/PER/10/2015 concerning Amendments to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1rUvUxyVExAo9dzYgSOnAMhWy1EFiMjLn/preview",
                },
              ],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Letter of Appointment to PT Anindya Wiraputra Kosult for Electronics",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 782 of the Year 2019 concerning Delegation of Surveyors to execute Verification or Technical Investigation for the Import of Certain Products.",
              url: "https://drive.google.com/file/d/1s0qJqjcKlo08tiSX3QWJxOs5uHeO0l7e/preview",
              rev: [],
            },
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 354 of the Year 2024 concerning the Appointment of PT Anindya Wiraputra Konsult (Persero) as a Surveyor to Execute Verification or Technical Investigation for the Import of Electronics",
              url: "https://drive.google.com/file/d/1mTvPFZMLcl6OKScVovORzQowRgJ_S1sd/view?usp=sharing",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            { title: "Country of origin and port of loading", val: [] },
            {
              title: "Description of goods and Post Tariff / HS Code",
              val: [],
            },
            {
              title:
                "Product Certification Number Using National Standard Mark (SPPT SNI), for certain required products",
              val: [],
            },
            { title: "Goods Registration Number (NPB);", val: [] },
            {
              title:
                "Instructions for use (manuals) and after-sales warranty/guarantee cards in Indonesian, for certain required products",
              val: [],
            },
            {
              title:
                "Certification of Telecommunication Tools and Equipment (POSTEL), for certain required products",
              val: [],
            },
            { title: "Shipping time", val: [] },
            { title: "Destination port", val: [] },
          ],
        },
      ],
      catatan: [
        {
          title: "Notes",
          text: "Electronics has 93 Post Tariff Codes required for VPTI.",
        },
      ],
      kontak: [
        {
          title: "Contact Us",
          nama: "Mary Lee",
          email: "Mary.lee_lsi@anindya.biz",
          telp: "+62 811-917-708",
        },
        {
          title: "",
          nama: "Dinda Salsa",
          email: "Dinda_lsi@anindya.biz",
          telp: "+62 812-3123-0443",
        },
      ],
      clients: [
        "Panasonic",
        "Acer",
        "Aqua Japan",
        "Fujifilm",
        "Gree",
        "Tefal",
        "Asus",
        "Sharp",
        "Lenovo",
        "Phillips",
        "Hp",
      ],
    },
    {
      tab_title: "HORTICULTURAL",
      pic: "hortikultura",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021",
              url: "https://drive.google.com/file/d/1Q7_nQMzqbRzyuygScu4pIJ0lW5uJXoR0/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2020 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1MS7sSnMARqx5qbwEnaRDDXmOZ-PksFQz/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 16 of the Year 2021",
              url: "https://drive.google.com/file/d/1uym_4R1K-cW25M_fJQmCkBhJa_5VEgt6/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade of the Republic of Indonesia Number 44 of the Year 2019 concerning Provisions for the Import of Horticultural Products.",
              url: "https://drive.google.com/file/d/170gcu-NvCKThIxV5SNwgDegKE-jB09dL/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade of the Republic of Indonesia Number 27 of the Year 2020 concerning Amendments to Regulation of the Minister of Trade Number 44 of the Year 2019 concerning Provisions for the Import of Horticultural Products.",
                  url: "https://drive.google.com/file/d/1GMMxCyTyYlwK-CGogFtjCz-euFnqxpFM/preview",
                },
              ],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Appointment Letter of PT Anindya Wiraputra Kosult for Horticultural Products",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 779 of the Year 2021 concerning the Appointment of PT Anindya Wiraputra Konsult as the Surveyor to Execute Verification or Technical Investigation for the Import of Horticultural Product.",
              url: "https://drive.google.com/file/d/1sXQ17iBOv4DBHvMaUmpgbxO1AY3Wtiyq/preview",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            { title: "Country and port of origin", val: [] },
            { title: "Product Description & Tariff Post / HS Code", val: [] },
            { title: "Type & volume", val: [] },
            { title: "Shipping time", val: [] },
            { title: "Destination port", val: [] },
            {
              title: "Food safe & recycling symbols in the packaging",
              val: [],
            },
            { title: "Health certificate", val: [] },
            { title: "Phytosanitary Certificate", val: [] },
            { title: "Certificate of Origin", val: [] },
            {
              title: "Food grade packaging certificate or statement",
              val: [],
            },
          ],
        },
      ],
      catatan: [
        {
          title: "Notes",
          text: "There are 2 types of Import Approval (PI) from the Minister of Trade, namely PI for Fresh Horticultural Products for Consumption and Fresh Horticultural Products for Industrial Raw Materials.",
        },
      ],
      kontak: [
        {
          title: "Contact Us",
          nama: "Almira Nurdiana",
          email: "almira.nurdiana_lsi@anindya.biz",
          telp: "+62 812-1325-2190",
        },
        {
          title: "",
          nama: "Stefanus Pradoddy",
          email: "stefanus_lsi@anindya.biz",
          telp: "+62 815-6003-190",
        },
      ],
      clients: ["Calbee Wings", "Jakarta Sereal"],
    },
    {
      tab_title: "GLASS SHEET",
      pic: "kaca-lembaran",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021",
              url: "https://drive.google.com/file/d/1Q7_nQMzqbRzyuygScu4pIJ0lW5uJXoR0/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2020 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1MS7sSnMARqx5qbwEnaRDDXmOZ-PksFQz/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 16 of the Year 2021",
              url: "https://drive.google.com/file/d/1uym_4R1K-cW25M_fJQmCkBhJa_5VEgt6/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade of the Republic of Indonesia Number 40 of the Year 2009 concerning Provisions for the Import of Glass Sheet.",
              url: "https://drive.google.com/file/d/1O9Tfsbe_VJjLdmAl4ppV0LiYksW88gdD/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade of the Republic of Indonesia Number 71 of the Year 2012 concerning the Amendments to Regulation of the Minister of Trade Number 40 of the Year 2009 concerning Provisions for the Import of Glass Sheet",
                  url: "https://drive.google.com/file/d/1XSzssW9I8g64eLbyUm2buChJbsSWHoZ2/preview",
                },
                {
                  text: "Regulation of the Minister of Trade of the Republic of Indonesia Number 92 of the Year 2017 concerning the Second Amendment to Regulation of the Minister of Trade Number 40 of the Year 2009 concerning Provisions for the Import of Glass Sheet",
                  url: "https://drive.google.com/file/d/1Q1Yt6XgMEeewLt-aM6P_CbklIJKZC0H9/preview",
                },
                {
                  text: "Regulation of the Minister of Trade of the Republic of Indonesia Number 09 of the Year 2018 concerning the Third Amendment to Regulation of the Minister of Trade Number 40 of the Year 2009 concerning Provisions for the Import of Glass Sheet",
                  url: "https://drive.google.com/file/d/10T1XgOo1kGwsLJiNAwhDePzLLJNtKIuV/preview",
                },
              ],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Letter of Appointment to PT Anindya Wiraputra Kosult for Glass Sheet",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 1241 of the Year 2023 concerning Delegation of Surveyors to execute Verification or Technical Investigation for the Import of Glass Sheet.",
              url: "https://drive.google.com/file/d/1mNQRUXdi0pfJWM2LtR1VRaxf_2g6QOgG/preview",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            {
              title: "Description of goods and Tariff Post / HS code",
              val: [],
            },
            { title: "Volume / Quantity of Goods", val: [] },
            { title: "Specification of Goods", val: [] },
            { title: "Destination Port", val: [] },
            { title: "Exporter & Importer Details", val: [] },
            {
              title: "Other Provisions Required by Laws and Regulations",
              val: [],
            },
          ],
        },
      ],
      catatan: [
        {
          title: "Notes",
          text: "Specifically for API-P, there are Complementary PI, goods for market test purposes, and/or goods for after-sales service",
        },
      ],
      kontak: [
        {
          title: "Contact Us",
          nama: "Karin Narita",
          email: "karin_lsi@anindya.biz",
          telp: "+62 857-8027-9028",
        },
      ],
      clients: ["Bernadi Utama", "Hyundai Motor", "Freeport", "Rolls-royce"],
    },
    {
      tab_title: "CERAMIC",
      pic: "keramik",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021",
              url: "https://drive.google.com/file/d/1Q7_nQMzqbRzyuygScu4pIJ0lW5uJXoR0/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2020 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1MS7sSnMARqx5qbwEnaRDDXmOZ-PksFQz/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 16 of the Year 2021",
              url: "https://drive.google.com/file/d/1uym_4R1K-cW25M_fJQmCkBhJa_5VEgt6/preview",
              rev: [],
            },
            {
              main: "Regulation of The Minister of Trade (PERMENDAG) Number 06/M-DAG/PER/1/2007 concerning Verification or Technical Investigation for the Import of Ceramic.",
              url: "https://drive.google.com/file/d/1kg-ZltuTemWnJf6eu9sim83rZkihi6SL/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (Permendag) Number 10 of the Year 2018 concerning the Third Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 06/M-DAG/PER/1/2007 concerning Verification or Technical Investigation for the Import of Ceramic;",
                  url: "https://drive.google.com/file/d/1Ho0WzCx2N20CyIjHjrt8_9RWNVrmDTlj/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 72/M-DAG/PER/11/2012 concerning the Second Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 06/M-DAG/PER/1/2007 concerning Verification or Technical Investigation for the Import of Ceramic;",
                  url: "https://drive.google.com/file/d/1Vo9ur4WzDP7h9gmbwb7lMXAJZBWCfaWO/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 25/M-DAG/PER/6/2007 concerning Amendments to the Regulation of the Minister of Trade (PERMENDAG) Number 06/M-DAG/PER/1/2007 concerning Verification or Technical Investigation for the Import of Ceramic..",
                  url: "https://drive.google.com/file/d/1iAGvtlTC1fARqYNxEryusqWbC4jy9BMX/preview",
                },
              ],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Appointment Letter of PT Anindya Wiraputra Kosult for Ceramic",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 778 of 2021 concerning the Appointment of PT Anindya Wiraputra Konsult as the Surveyor to Execute Verification or Technical Investigation for the Import of Ceramic.",
              url: "https://drive.google.com/file/d/16oBelH4RHwKvAbMRcXTn8ZNuH6jHy34u/preview",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            {
              title:
                "Description and specifications of goods which include tariff post/HS Code",
              val: [],
            },
            {
              title: "Amount (volume) and net weight per type of goods",
              val: [],
            },
            {
              title:
                "Data or information regarding the country of origin of the goods",
              val: [],
            },
            { title: "Shipping time.", val: [] },
          ],
        },
      ],
      catatan: [
        {
          title: "Notes",
          text: "Post Border Commodities, under Sub-Directorate III (Miscellaneous Industrial Goods and Industrial Raw Materials)",
        },
      ],
      kontak: [
        {
          title: "Contact Us",
          nama: "Karin Narita",
          email: "karin_lsi@anindya.biz",
          telp: "+62 857-8027-9028",
        },
      ],
      clients: [
        "Goldengres",
        "American Standard",
        "Kohler",
        "Informa",
        "Kkv",
        "Lixil",
        "Nakshatra",
        "Torch Ceramics",
      ],
    },
    {
      tab_title: "COSMETICS AND HOUSEHOLD GOODS",
      pic: "kosmetik",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021",
              url: "https://drive.google.com/file/d/1Q7_nQMzqbRzyuygScu4pIJ0lW5uJXoR0/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2020 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1MS7sSnMARqx5qbwEnaRDDXmOZ-PksFQz/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 16 of the Year 2021",
              url: "https://drive.google.com/file/d/1uym_4R1K-cW25M_fJQmCkBhJa_5VEgt6/preview",
              rev: [],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Decree of the Minister of Trade (KEPMENDAG) Number 782 of 2019 concerning the Delegation of Surveyors to Execute Verification or Technical Investigation of the Import of Certain Products.",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 782 of the Year 2019 concerning Delegation of Surveyors to execute Verification or Technical Investigation for the Import of Certain Products.",
              url: "https://drive.google.com/file/d/1s0qJqjcKlo08tiSX3QWJxOs5uHeO0l7e/preview",
              rev: [],
            },
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 358 of the Year 2024 concerning the Appointment of PT Anindya Wiraputra Konsult (Persero) as a Surveyor to Execute Verification or Technical Investigation for the Import of Cosmetics and Household Goods",
              url: "https://drive.google.com/file/d/1gJeN6VTQCt-Ii7cfNMhvHjm7nysru5lx/view?usp=sharing",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            { title: "Country of origin and port of loading;", val: [] },
            { title: "Description of goods and Tariff Post / HS;", val: [] },
            {
              title:
                "Product Certification Number Using National Standard Mark (SPPT SNI), for certain products;",
              val: [],
            },
            { title: "Goods Registration Number (NPB);", val: [] },
            {
              title:
                "Certificate of Analysis (COA), for certain required products;",
              val: [],
            },
            { title: "Shipping time", val: [] },
            { title: "Destination port;", val: [] },
            { title: "SKI BPOM, NIE; and", val: [] },
            {
              title: "Other provisions required by laws and regulations.",
              val: [],
            },
          ],
        },
      ],
      catatan: [],
      kontak: [
        {
          title: "Contact Us",
          nama: "Nadila Ramadhana",
          email: "nadila_lsi@anindya.biz",
          telp: "+62 821-2151-5104",
        },
      ],
      clients: [
        "BBW",
        "oriflame",
        "JUST MISS",
        "the body shop",
        "Shiseido",
        "sociolla",
        "victoria secret",
      ],
    },
    {
      tab_title: "CHILDREN'S TOYS",
      pic: "mainan",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021",
              url: "https://drive.google.com/file/d/1Q7_nQMzqbRzyuygScu4pIJ0lW5uJXoR0/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2020 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1MS7sSnMARqx5qbwEnaRDDXmOZ-PksFQz/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 16 of the Year 2021",
              url: "https://drive.google.com/file/d/1uym_4R1K-cW25M_fJQmCkBhJa_5VEgt6/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products.",
              url: "https://drive.google.com/file/d/1GlLNdFwDHVvIlY-9CVj0HBvBj7N9F8VR/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 28 of the Year 2020 concerning the Eighth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1JWCPymLibiK72u7tzyg3gN8llytWXMI0/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 24 of the Year 2019 concerning the Seventh Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1Wa66lbZnpuBrmStAy-pf4pao3Dj-pADF/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 121 of the Year 2018 concerning the Sixth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1PyaTiSPoFZrj1JRmGC43iI8In_pG_ZjM/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 42 of the Year 2018 concerning the Fifth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1bQ-LTq8QaLEQry2D0DCOZ-30CGPsTa3N/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 12 of the Year 2018 concerning the Fourth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1LT5jW3R1CNXaES45UUiSD-TyoMbTAOGF/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 94 of the Year 2017 concerning the Third Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1HkEDhBMj6Lg7FppOR06J6uJctIQDo4NI/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 81 of the Year 2017 concerning the Second Amendment to the Regulation of the Minister of Trade (Permendag) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1rUBYQFKDKJHyvKXENybVbcYdl5feHDts/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 94/M-DAG/PER/10/2015 concerning Amendments to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1rUvUxyVExAo9dzYgSOnAMhWy1EFiMjLn/preview",
                },
              ],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Letter of Appointment to PT Anindya Wiraputra Kosult for Children's Toys",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 782 of the Year 2019 concerning Delegation of Surveyors to execute Verification or Technical Investigation for the Import of Certain Products.",
              url: "https://drive.google.com/file/d/1s0qJqjcKlo08tiSX3QWJxOs5uHeO0l7e/preview",
              rev: [],
            },
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 357 of the Year 2024 concerning the Appointment of PT Anindya Wiraputra Konsult (Persero) as a Surveyor to Execute Verification or Technical Investigation for the Import of Children\u2019s Toys.",
              url: "https://drive.google.com/file/d/1xxY4rj4M9Ly-ldpO-8qBhgXfE9Ql4SkY/view?usp=sharing",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            { title: "Country of origin and port of loading", val: [] },
            {
              title: "Description of goods and Tariff Post / HS Code",
              val: [],
            },
            {
              title:
                "Product Certification Number Using National Standard Mark (SPPT SNI), for certain required products",
              val: [],
            },
            { title: "Goods Registration Number (NPB);", val: [] },
            {
              title:
                "Instructions for use (manuals) and after-sales warranty/guarantee cards in Indonesian, for certain required products",
              val: [],
            },
            { title: "Shipping time", val: [] },
            { title: "Destination port", val: [] },
          ],
        },
      ],
      catatan: [
        {
          title: "Notes",
          text: "Children's Toys consist of 29 Tariff Post Code required for VPTI.",
        },
      ],
      kontak: [
        {
          title: "Contact Us",
          nama: "Tia Regita",
          email: "tiaregita_lsi@anindya.biz",
          telp: "+62 811-9279-197",
        },
        {
          title: "",
          nama: "Zaki Miftah",
          email: "zaki.miftah_lsi@anindya.biz",
          telp: "+62 812-8968-6859",
        },
      ],
      clients: ["Emco", "ELC", "Mothercare", "Tomica"],
    },
    {
      tab_title: "FOOD & BEVERAGE",
      pic: "makanan",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021",
              url: "https://drive.google.com/file/d/1Q7_nQMzqbRzyuygScu4pIJ0lW5uJXoR0/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2020 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1MS7sSnMARqx5qbwEnaRDDXmOZ-PksFQz/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 16 of the Year 2021",
              url: "https://drive.google.com/file/d/1uym_4R1K-cW25M_fJQmCkBhJa_5VEgt6/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products.",
              url: "https://drive.google.com/file/d/1GlLNdFwDHVvIlY-9CVj0HBvBj7N9F8VR/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 28 of the Year 2020 concerning the Eighth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1JWCPymLibiK72u7tzyg3gN8llytWXMI0/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 24 of the Year 2019 concerning the Seventh Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1Wa66lbZnpuBrmStAy-pf4pao3Dj-pADF/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 121 of the Year 2018 concerning the Sixth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1PyaTiSPoFZrj1JRmGC43iI8In_pG_ZjM/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 42 of the Year 2018 concerning the Fifth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1bQ-LTq8QaLEQry2D0DCOZ-30CGPsTa3N/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 12 of the Year 2018 concerning the Fourth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1LT5jW3R1CNXaES45UUiSD-TyoMbTAOGF/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 94 of the Year 2017 concerning the Third Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1HkEDhBMj6Lg7FppOR06J6uJctIQDo4NI/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 81 of the Year 2017 concerning the Second Amendment to the Regulation of the Minister of Trade (Permendag) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1rUBYQFKDKJHyvKXENybVbcYdl5feHDts/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 94/M-DAG/PER/10/2015 concerning Amendments to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1rUvUxyVExAo9dzYgSOnAMhWy1EFiMjLn/preview",
                },
              ],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Letter of Appointment to PT Anindya Wiraputra Kosult for Food & Beverage",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 782 of the Year 2019 concerning Delegation of Surveyors to execute Verification or Technical Investigation for the Import of Certain Products.",
              url: "https://drive.google.com/file/d/1s0qJqjcKlo08tiSX3QWJxOs5uHeO0l7e/preview",
              rev: [],
            },
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 356 of the Year 2024 concerning the Appointment of PT Anindya Wiraputra Konsult (Persero) as a Surveyor to Execute Verification or Technical Investigation for the Import of Food and Beverages",
              url: "https://drive.google.com/file/d/1mVhJVE9hFp1r_lgq0mnrsoriaT-NTWmU/view?usp=sharing",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            { title: "Country of origin and port of loading;", val: [] },
            {
              title: "Description of goods and Tariff Post / HS Code",
              val: [],
            },
            {
              title:
                "Product Certification Number Using National Standard Mark (SPPT SNI), for certain required products",
              val: [],
            },
            { title: "Goods Registration Number (NPB);", val: [] },
            {
              title:
                "Certificate of Analysis (COA), for certain required products",
              val: [],
            },
            { title: "Shipping time", val: [] },
            { title: "Destination port", val: [] },
          ],
        },
      ],
      catatan: [
        {
          title: "Notes",
          text: "Food and Beverage consist of 231 Tariff Post Code required for VPTI.",
        },
      ],
      kontak: [
        {
          title: "Contact Us",
          nama: "Tia Regita",
          email: "tiaregita_lsi@anindya.biz",
          telp: "+62 811-9279-197",
        },
        {
          title: "",
          nama: "Zaki Miftah",
          email: "zaki.miftah_lsi@anindya.biz",
          telp: "+62 812-8968-6859",
        },
      ],
      clients: [
        "Ajinomoto",
        "Chatime",
        "Starbucks",
        "Cold Stone",
        "Mamasuka",
        "Mixue",
      ],
    },
    {
      tab_title:
        "COLOR MULTIFUNCTION MACHINE, COLOR PHOTOCOPY MACHINE, AND COLOR PRINTER MACHINE",
      pic: "mesin",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021",
              url: "https://drive.google.com/file/d/1Q7_nQMzqbRzyuygScu4pIJ0lW5uJXoR0/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2020 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1MS7sSnMARqx5qbwEnaRDDXmOZ-PksFQz/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 16 of the Year 2021",
              url: "https://drive.google.com/file/d/1uym_4R1K-cW25M_fJQmCkBhJa_5VEgt6/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 102/M-DAG/PER/12/2015 concerning Provisions for the Import of Color Multifunction Machine, Color Photocopy Machine, and Color Printer Machine.",
              url: "https://drive.google.com/file/d/19u0jl9cx9ljfSH5AdlAnqb5gtb4J3WHW/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 14 of the Year 2018 concerning Amendments to Regulation of the Minister of Trade (PERMENDAG) Number 102/M-DAG/PER/12/2015 concerning Provisions on Import of Color Multifunction Machine, Color Photocopy Machine, and Color Printer Machine.",
                  url: "https://drive.google.com/file/d/1Ef_x3xpD7Pyo7jl370sPGXhrEFkQruNL/preview",
                },
              ],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Appointment Letter of PT Anindya Wiraputra Kosult for Color Multifunction Machines, Color Photocopy Machine, and Color Printer Machine",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 367 of the Year 2020 concerning Delegation of Surveyors to Execute Verification or Technical Investigation of the Import of Color Multifunction Machine, Color Photocopy Machine, and Color Printer Machine.",
              url: "https://drive.google.com/file/d/1Jy9QhMqitBHHTjwmRkfPjzVZV3kS8aDS/preview",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            { title: "Country of origin and port of loading", val: [] },
            {
              title: "Description of goods and Tariff Post / HS Code",
              val: [],
            },
            { title: "Type, quantity, and specification of goods", val: [] },
            { title: "Item type", val: [] },
            { title: "Shipping time", val: [] },
            { title: "Destination port", val: [] },
          ],
        },
      ],
      catatan: [
        { title: "Notes", text: "API with recommendation from BOTASUPAL." },
      ],
      kontak: [
        {
          title: "Contact Us",
          nama: "Silvia Wulandary",
          email: "silvia.wulandary_lsi@anindya.biz",
          telp: "+62 822-1666-4542",
        },
        {
          title: "",
          nama: "Teddy",
          email: "teddy_lsi@anindya.biz",
          telp: "+62 813-2237-3773",
        },
      ],
      clients: ["Ricoh", "Fujifilm", "Canon", "Hp"],
    },
    {
      tab_title: "TRADITIONAL MEDICINE AND HEALTH SUPPLEMENTS",
      pic: "obat",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021",
              url: "https://drive.google.com/file/d/1Q7_nQMzqbRzyuygScu4pIJ0lW5uJXoR0/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2020 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1MS7sSnMARqx5qbwEnaRDDXmOZ-PksFQz/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 16 of the Year 2021",
              url: "https://drive.google.com/file/d/1uym_4R1K-cW25M_fJQmCkBhJa_5VEgt6/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products.",
              url: "https://drive.google.com/file/d/1GlLNdFwDHVvIlY-9CVj0HBvBj7N9F8VR/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 28 of the Year 2020 concerning the Eighth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1JWCPymLibiK72u7tzyg3gN8llytWXMI0/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 24 of the Year 2019 concerning the Seventh Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1Wa66lbZnpuBrmStAy-pf4pao3Dj-pADF/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 121 of the Year 2018 concerning the Sixth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1PyaTiSPoFZrj1JRmGC43iI8In_pG_ZjM/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 42 of the Year 2018 concerning the Fifth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1bQ-LTq8QaLEQry2D0DCOZ-30CGPsTa3N/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 12 of the Year 2018 concerning the Fourth Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1LT5jW3R1CNXaES45UUiSD-TyoMbTAOGF/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 94 of the Year 2017 concerning the Third Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1HkEDhBMj6Lg7FppOR06J6uJctIQDo4NI/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 81 of the Year 2017 concerning the Second Amendment to the Regulation of the Minister of Trade (Permendag) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products;",
                  url: "https://drive.google.com/file/d/1rUBYQFKDKJHyvKXENybVbcYdl5feHDts/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 94/M-DAG/PER/10/2015 concerning Amendments to the Regulation of the Minister of Trade (PERMENDAG) Number 87/M-DAG/PER/10/2015 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1rUvUxyVExAo9dzYgSOnAMhWy1EFiMjLn/preview",
                },
              ],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Letter of Appointment of PT Anindya Wiraputra Kosult for Traditional Medicine Products and Health Supplements",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 782 of the Year 2019 concerning Delegation of Surveyors to execute Verification or Technical Investigation for the Import of Certain Products.",
              url: "https://drive.google.com/file/d/1s0qJqjcKlo08tiSX3QWJxOs5uHeO0l7e/preview",
              rev: [],
            },
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 359 of the Year 2024 concerning the Appointment of PT Anindya Wiraputra Konsult (Persero) as a Surveyor to Execute Verification or Technical Investigation for the Import of Traditional Medicine and Health Supplements",
              url: "https://drive.google.com/file/d/14attAKFpdVK4sfdfXR5152nzEqKHXddJ/view?usp=sharing",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            { title: "Country of origin and port of loading", val: [] },
            {
              title: "Description of goods and postal Tariff Post / HS Number",
              val: [],
            },
            {
              title:
                "Product Certification Number Using the National Standard Mark (SPPT SNI), for certain Products that is deemed mandatory",
              val: [],
            },
            { title: "Goods Registration Number (NPB)", val: [] },
            {
              title:
                "Certificate of Analysis (COA), for certain required products",
              val: [],
            },
            { title: "Shipping time", val: [] },
            { title: "Destination port.", val: [] },
          ],
        },
      ],
      catatan: [
        {
          title: "Notes",
          text: "Traditional Medicines and Food Supplements consist of 30 Tariff Post Code required for VPTI.",
        },
      ],
      kontak: [
        {
          title: "Contact Us",
          nama: "Tia Regita",
          email: "tiaregita_lsi@anindya.biz",
          telp: "+62 811-9279-197",
        },
        {
          title: "",
          nama: "Zaki Miftah",
          email: "zaki.miftah_lsi@anindya.biz",
          telp: "+62 812-8968-6859",
        },
      ],
      clients: ["Herbalife"],
    },
    {
      tab_title: "APPAREL AND APPAREL ACCESSORIES",
      pic: "pakaian-jadi",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade of the Republic of Indonesia Number 20 of 2021 concerning Import Policies and Regulations",
              url: "https://drive.google.com/file/d/1xG56RUzkaT7LTu1_IqprxSZrtiehm_Gm/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade of the Republic of Indonesia Number 16 of 2021 concerning Verification or Technical Investigation in the Field of Foreign Trade",
              url: "https://drive.google.com/file/d/1QlQ8aqKmiJl-4cpExX-B27HRtQ9VBug_/preview",
              rev: [],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Letter of Appointment of PT Anindya Wiraputra Kosult for Apparel and Apparel Accessories",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade of the Republic of Indonesia Number 1491 of 2022 concerning the Appointment of PT Anindya Wiraputra Konsult as a Surveyor Implementing Verification or Technical Tracing of Imported Apparel and Apparel Accessories",
              url: "https://drive.google.com/file/d/1Cc2-owVJyYeq_ElAawTYFjYbJlE-p5nk/preview",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            { title: "Country of origin and port of loading;", val: [] },
            {
              title: "Description of goods and Harmonized System (HS) code;",
              val: [],
            },
            {
              title:
                "Product Certification Number for Using the SNI Mark (SPPT SNI), for certain Products whose SNI is compulsorily enforced;",
              val: [],
            },
            { title: "Goods Registration Number (NPB);", val: [] },
            { title: "Shipping time;", val: [] },
            { title: "Destination port; And", val: [] },
            {
              title: "Other provisions required in statutory regulations.",
              val: [],
            },
          ],
        },
      ],
      catatan: [],
      kontak: [
        {
          title: "Contact Us",
          nama: "Gunawan Pragusty Indrabuana",
          email: "gunawan_lsi@anindya.biz",
          telp: "+62 812-2392-6667",
        },
        {
          title: "",
          nama: "Victoria Jesslyn Hong",
          email: "victoria_lsi@anindya.biz",
          telp: "+62 851-7117-2891",
        },
      ],
      clients: ["h&m", "Lavin Paris", "The North Face"],
    },
    {
      tab_title: "DOWNSTREAM PRODUCT OF PLASTIC",
      pic: "plastik",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Letter of Appointment of PT Anindya Wiraputra Kosult for Downstream Plastic",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade of the Republic of Indonesia Number 463 of 2024 concerning the Appointment of PT Anindya Wiraputra Konsult as Surveyor Implementing Verification or Technical Tracing of Downstream Plastic Imports",
              url: "https://drive.google.com/file/d/1TLZ9dovJVpZa9hMRe7jlaxlGHo2KHJpn/preview",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            {
              title: "Description of goods and Harmonized System (HS) code;",
              val: [],
            },
            { title: "The amount of goods;", val: [] },
            { title: "Unit of goods;", val: [] },
            { title: "Country of origin;", val: [] },
            { title: "Destination port;", val: [] },
            { title: "Description/specifications of goods;", val: [] },
            { title: "Loading country/loading port;", val: [] },
            { title: "Name and address of Importer and Exporter;", val: [] },
            {
              title: "Data or other information on the PI as required;",
              val: [],
            },
            {
              title:
                "Product Certification Number for Using the SNI Mark (SPPT SNI) and Goods Registration Number (NPB) for those whose SNI is applied compulsorily; and",
              val: [],
            },
            {
              title: "Other provisions required in statutory regulations.",
              val: [],
            },
          ],
        },
      ],
      catatan: [],
      kontak: [
        {
          title: "Contact Us",
          nama: "Aprodita",
          email: "j.aprodita_lsi@anindya.biz",
          telp: "+62 817-885-667",
        },
        {
          title: "",
          nama: "Rasikha Windayana",
          email: "rasikha.kamila_lsi@anindya.biz",
          telp: "+62 811-1089-844",
        },
      ],
      clients: ["Baby Safe", "Beaba", "Lock n Lock"],
    },
    {
      tab_title: "PRECURSOR",
      pic: "prekursor",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021",
              url: "https://drive.google.com/file/d/1Q7_nQMzqbRzyuygScu4pIJ0lW5uJXoR0/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2020 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1MS7sSnMARqx5qbwEnaRDDXmOZ-PksFQz/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 16 of the Year 2021",
              url: "https://drive.google.com/file/d/1uym_4R1K-cW25M_fJQmCkBhJa_5VEgt6/preview",
              rev: [],
            },
            {
              main: "Decree of the Minister of Industry and the Minister of Trade (KEPMENPERINDAG) Number 647/MPP/KEP/10/2004 concerning Provisions on Import of Precursor.",
              url: "https://drive.google.com/file/d/1Gx9a8pse4JsdvCDaa20BAFhmXBFFZjaF/preview",
              rev: [
                {
                  text: "Decree of the Minister of Industry and the Minister of Trade (KEPMENPERINDAG) Number 649/MPP/KEP/10/2004 concerning Provisions on Import of Precursor;",
                  url: "https://drive.google.com/file/d/1IBi2ejhmA6alchhvTKdTTW6jjJM-z-Is/preview",
                },
                {
                  text: "Regulation of the Minister of Health of the Republic of Indonesia Number 26 of the Year 2014.",
                  url: "http://hukor.kemkes.go.id/uploads/produk_hukum/PMK%20No.%2026%20ttg%20Kebutuhan%20Narkotika,%20Psikotropika%20dan%20Prekursor.pdf",
                },
              ],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Letter of Appointment to PT Anindya Wiraputra Kosult for Precursor Products",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Industry and the Minister of Trade (KEPMENPERINDAG) Number 780 of the Year 2021 concerning the Delegation of PT Anindya Wiraputra Konsult as the Surveyor to execute Verification or Technical Investigation of the Import of Precursor.",
              url: "https://drive.google.com/file/d/1HDPRKTseRv_79cgeDIl1hwAHawyyggZC/preview",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            { title: "Name and address of exporter", val: [] },
            { title: "Country of manufacture", val: [] },
            {
              title: "Description of goods and Tariff Post / HS number",
              val: [],
            },
            { title: "Types and technical specifications", val: [] },
            { title: "Chemical composition of the precursor and", val: [] },
            { title: "The number of precursors to be imported", val: [] },
          ],
        },
      ],
      catatan: [
        {
          title: "Notes",
          text: "For IP - Precursor there is a Recommendation from the Ministry of Industry's DJ IAK, while for IT - Precursor there is a Recommendation from the Head of the Indonesian Police Criminal Investigation Agency (KABARESKRIM POLRI) and the Head of the National Narcotics Board (BNN).",
        },
      ],
      kontak: [
        {
          title: "Contact Us",
          nama: "Nadila Ramadhana",
          email: "nadila_lsi@anindya.biz",
          telp: "+62 821-2151-5104",
        },
      ],
      clients: ["Petrokimia Gresik", "Aik Moh", "BSP", "SSK", "HSP"],
    },
    {
      tab_title: "TWO WHEEL AND THREE WHEEL BICYCLE",
      pic: "sepeda",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021",
              url: "https://drive.google.com/file/d/1Q7_nQMzqbRzyuygScu4pIJ0lW5uJXoR0/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2020 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1MS7sSnMARqx5qbwEnaRDDXmOZ-PksFQz/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 16 of the Year 2021",
              url: "https://drive.google.com/file/d/1uym_4R1K-cW25M_fJQmCkBhJa_5VEgt6/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 68 of the Year 2020 concerning Provisions for the Import of Footwear, Electronics, and Two-Wheel and Three-Wheel Bikes.",
              url: "https://drive.google.com/file/d/1Yr3SltRGlXUBDkBnmTZr2FXXynUXZQXM/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 78 of the Year 2020 concerning Amendments to Regulation of the Minister of Trade (PERMENDAG) Number 68 of the Year 2020 concerning Provisions for the Import of Footwear, Electronics, and Two-Wheel and Three-Wheel Bikes.",
                  url: "https://drive.google.com/file/d/1o47ZpzBwRlLKS10Ry9Q6CwUJi4DX4ciD/preview",
                },
              ],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Letter of Appointment of PT Anindya Wiraputra Kosult for Two-Wheel and Three-Wheel Bicycle Products",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 1067 of the Year 2020 concerning the Appointment of PT Anindya Wiraputra Konsult (Persero) as a Surveyor to Execute Verification or Technical Investigation for the Import of Footwear, Electronics, and Two-Wheel and Three-Wheel Bikes.",
              url: "https://drive.google.com/file/d/1j54C2Cw48yguhx_UU9fhaY--8mbLGgQp/preview",
              rev: [],
            },
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 355 of the Year 2024 concerning the Appointment of PT Anindya Wiraputra Konsult (Persero) as a Surveyor to Execute Verification or Technical Investigation for the Import of Two-Wheel and Three-Wheel Bikes",
              url: "https://drive.google.com/file/d/1U0gRs_mUqP5DpKDINvIgbXtE07WopDuf/view?usp=sharing",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            { title: "Country of origin and port of loading;", val: [] },
            { title: "Information on the type of goods", val: [] },
            {
              title:
                "Product Certification Number for Using National Standard Mark (SNI), for two-wheel and three-wheel bicycle products that are deemed mandatory",
              val: [],
            },
            { title: "Goods Registration Number (NPB)", val: [] },
            { title: "Shipping time", val: [] },
            { title: "Destination port", val: [] },
          ],
        },
      ],
      catatan: [
        {
          title: "Notes",
          text: "Bicycles for Racing or Competition purposes, requires content or information regarding the weight, height, and description of the bicycle after it is assembled.",
        },
      ],
      kontak: [
        {
          title: "Contact Us",
          nama: "Tia Regita",
          email: "tiaregita_lsi@anindya.biz",
          telp: "+62 811-9279-197",
        },
        {
          title: "",
          nama: "Zaki Miftah",
          email: "zaki.miftah_lsi@anindya.biz",
          telp: "+62 812-8968-6859",
        },
      ],
      clients: [
        "Surly",
        "Cannondale",
        "Colnago",
        "Brompton",
        "United",
        "Pacific",
        "Specialized",
      ],
    },
    {
      tab_title: "BAG",
      pic: "tas",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title: "Letter of Appointment of PT Anindya Wiraputra Kosult for Bag",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade of the Republic of Indonesia Number 464 of 2024 concerning the Appointment of PT Anindya Wiraputra Konsult as the Surveyor Executing Verification or Technical Investigation of Bag Imports",
              url: "https://drive.google.com/file/d/15f-Ki1jl6hmAgybsCKqpTOv36nbGO_IJ/preview",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            {
              title: "Description of goods and Harmonized System (HS) code;",
              val: [],
            },
            { title: "The amount of goods;", val: [] },
            { title: "Unit of goods;", val: [] },
            { title: "Country of origin;", val: [] },
            { title: "Destination port;", val: [] },
            { title: "Description/specifications of goods;", val: [] },
            { title: "Loading country/loading port;", val: [] },
            { title: "Name and address of Importer and Exporter;", val: [] },
            {
              title: "Data or other information on the PI as required;",
              val: [],
            },
            {
              title:
                "Product Certification Number for Using the SNI Mark (SPPT SNI) and Goods Registration Number (NPB) for those whose SNI is applied compulsorily; and",
              val: [],
            },
            {
              title: "Other provisions required in statutory regulations.",
              val: [],
            },
          ],
        },
      ],
      catatan: [],
      kontak: [
        {
          title: "Contact Us",
          nama: "Gunawan Pragusty Indrabuana",
          email: "gunawan_lsi@anindya.biz",
          telp: "+62 812-2392-6667",
        },
        {
          title: "",
          nama: "Victoria Jesslyn Hong",
          email: "victoria_lsi@anindya.biz",
          telp: "+62 851-7117-2891",
        },
      ],
      clients: ["Hermes", "LV"],
    },
    {
      tab_title: "TEXTILE AND TEXTILE PRODUCT",
      pic: "tekstil",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1xG56RUzkaT7LTu1_IqprxSZrtiehm_Gm/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 16 of the Year 2021 concerning Verification or Technical Investigation in the Field of Foreign Trade",
              url: "https://drive.google.com/file/d/1QlQ8aqKmiJl-4cpExX-B27HRtQ9VBug_/preview",
              rev: [],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Letter of Appointment to PT Anindya Wiraputra Kosult for Textile and Textile Product",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade of the Republic of Indonesia Number 145 of 2024 concerning the Appointment of PT Anindya Wiraputra Konsult as Surveyor Implementing Verification or Technical Investigation of Imports of Textiles and Textile Products of the Minister of Trade of the Republic of Indonesia",
              url: "https://drive.google.com/file/d/1MbaqlJSWb7NkQnwbGcBBxtAvDHayB9RT/preview",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [
        {
          title: "Scope",
          subtitle:
            "The scope of VPTI includes data & information at the minimum regarding:",
          val: [
            { title: "Importer's name and address", val: [] },
            { title: "Type and quantity of goods", val: [] },
            {
              title: "Description of goods and Harmonized System (HS) code",
              val: [],
            },
            { title: "Item specification", val: [] },
            { title: "Country and port of loading", val: [] },
            { title: "Shipping time", val: [] },
            { title: "Destination port; and", val: [] },
            {
              title: "Other provisions required in statutory regulations",
              val: [],
            },
          ],
        },
      ],
      catatan: [],
      kontak: [
        {
          title: "Contact Us",
          nama: "Eko Mahar Diputra",
          email: "eko.mahar_lsi@anindya.biz",
          telp: "+62 817-4392-87",
        },
        {
          title: "",
          nama: "Adji Mochamad",
          email: "adji.mochamad_lsi@anindya.biz",
          telp: "+62 812-2996-841",
        },
      ],
      clients: [
        "Inan",
        "ITTI",
        "Lava",
        "PT Mahugi",
        "PT Trimulia Warnajaya",
        "PT Sukses Investa Anugrah Propertindo",
        "Subur Anugerah Tangerang Java Indonesia",
      ],
    },
    {
      tab_title: "CELLULAR PHONE, HANDHELD, AND COMPUTER TABLET",
      pic: "telepon",
      dasar_hukum: [
        {
          title: "Legal Basis",
          permendag: [
            {
              main: "Regulation of the Minister of Trade Number 3 of the Year 2024 concerning the Amandement to the Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/11u05-Fdx9N1pST_c3BYAmPgKuf7tRb49/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 36 of the Year 2023 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1j007yQakw4u28o1x4LJxujemOVATYT-m/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021 concerning Import Policy and Regulation",
              url: "https://drive.google.com/file/d/1Z4WL--E2D0B7YAVvGVPEgkQ_uZJ2cz3R/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2021",
              url: "https://drive.google.com/file/d/1Q7_nQMzqbRzyuygScu4pIJ0lW5uJXoR0/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 25 of the Year 2022 concerning the Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 20 of the Year 2020 concerning Provisions for the Import of Certain Products.",
                  url: "https://drive.google.com/file/d/1MS7sSnMARqx5qbwEnaRDDXmOZ-PksFQz/preview",
                },
              ],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 16 of the Year 2021",
              url: "https://drive.google.com/file/d/1uym_4R1K-cW25M_fJQmCkBhJa_5VEgt6/preview",
              rev: [],
            },
            {
              main: "Regulation of the Minister of Trade (PERMENDAG) Number 82/M-DAG/PER/12/2012 concerning Provisions for the Import of Cellular Phone, Handheld, and Computers Tablet.",
              url: "https://drive.google.com/file/d/12XVAczCBOTtp78pUS_oNZjB-koAIupcq/preview",
              rev: [
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 41/M-DAG/PER/5/2016 concerning the 3rd Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 82/M-DAG/PER/12/2012 concerning Provisions for the Import of Cellular Phone, Handheld, and Computers Tablet;",
                  url: "https://drive.google.com/file/d/1vVL-vJ3CHwpcYaVCARtgqp0_Td1k0d6a/preview",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 48/M-DAG/PER/8/2014 concerning the 2nd Amendment to the Regulation of the Minister of Trade (PERMENDAG) Number 82/M-DAG/PER/12/2012 concerning Provisions for the Import of Cellular Phone, Handheld, and Computers Tablet;",
                  url: "http://jdih.kemendag.go.id/peraturan/detail/479/2",
                },
                {
                  text: "Regulation of the Minister of Trade (PERMENDAG) Number 38/M-DAG/PER/8/2013 concerning Amendments to the Regulation of the Minister of Trade (PERMENDAG) Number 82/M-DAG/PER/12/2012 concerning Provisions on the Import of Cellular Phone, Handheld, and Computers Tablet.",
                  url: "https://drive.google.com/file/d/1aMz6KipJHmSXxKnEERbIuOBIC-_H1ZMC/preview",
                },
              ],
            },
          ],
        },
      ],
      daftar_perubahan: [
        {
          title:
            "Letter of Appointment PT Anindya Wiraputra Kosult for Cellular Phone, Handheld, and Computers Tablet",
          permendag: [],
          kepmendag: [
            {
              main: "Decree of the Minister of Trade (KEPMENDAG) Number 366 of the Year 2020 concerning Delegation of Surveyors to execute Verification or Technical Investigation of the Import of Cellular Phone, Handheld, and Computers Tablet.",
              url: "https://drive.google.com/file/d/1gC2gNAp2trg6QMOiro0DPCI5AOVJKxFT/preview",
              rev: [],
            },
          ],
        },
      ],
      ruang_lingkup: [],
      catatan: [
        {
          title: "Notes",
          text: "There is a Director General's Industrial Investment Recommendation. ILMATE, Ministry of Industry.",
        },
      ],
      kontak: [
        {
          title: "Contact Us",
          nama: "Silvia Wulandary",
          email: "silvia.wulandary_lsi@anindya.biz",
          telp: "+62 822-1666-4542",
        },
        {
          title: "",
          nama: "Teddy",
          email: "teddy_lsi@anindya.biz",
          telp: "+62 813-2237-3773",
        },
      ],
      clients: ["Oppo", "Vivo", "Samsung", "Apple"],
    },
  ],
  indo_office: [
    {
      location: "Indonesia",
      address: "Springhill Office Tower 17th & 18th Floor",
      detail:
        "Jl. H. Benyamin Suaeb Ruas D7  Block D6 Pademangan Timur, Jakarta Utara 14410",
      email: "marketing@anindya.biz",
      phone: "+62 21 2260 6207",
      phone2: "+62 21 2260 6235",
    },
  ],
  indo_rep: [
    {
      location: "Surabaya",
      address: "Pakuwon Center Lantai 12, Tunjungan Plaza 5",
      detail: "Jl. Embong Malang no 1, 3, & 5",
      detail2: "Surabaya 60261",
      email: "",
      phone: "+62 31 99261088",
    },
    {
      location: "Palembang",
      address: "Komp. Pergudangan Sukarame Blok D No. 9",
      detail: "JI. Tembus Terminal",
      detail2:
        "Kel Talang Kelapa, Kec. Alang-Alang Lebar, Palembang, Sumatera Selatan 30151",
      email: "",
      phone: "",
    },
  ],
  our_offices: [
    {
      location: "Singapore",
      address: "5001 Beach Road",
      detail: "#07-14 Golden Mile Complex",
      detail2: "SINGAPORE 199588",
      email: "singapore@anindya.biz",
      phone: "",
    },
    {
      location: "China",
      address: "Room 1001, Block A, Hengda Tianjing Building, Nanhai Avenue,",
      detail: "Nanshan District, Shenzhen City, Guangdong Province/广东省",
      detail2: "深圳市南山区南海大道恒大天璟A座1001",
      email: "china@anindya.biz",
      phone: "",
    },
    {
      location: "Vietnam",
      address: "8th Floor, Le Meridien Building",
      detail: "3C Ton Duc Thang Street, Ben Nghe ward, District 1",
      detail2: "Ho Chi Minh City",
      email: "vietnam@anindya.biz",
      phone: "+84 28 730 999 86",
    },
    {
      location: "India",
      address: "S-02, Regus – Times Square",
      detail: "Unit 1, Level 2, B Wing, Andheri-Kurla Road,",
      detail2: "Marol, Andheri East, Mumbai – 400059",
      email: "india@anindya.biz",
      phone: "",
    },
    {
      location: "Bangladesh",
      address: "110 Bir Uttam C R Dutta Road",
      detail: "Dhaka 1205",
      detail2: "",
      email: "bangladesh@anindya.biz",
      phone: "",
    },
  ],
  our_offices_rep: [
    {
      location: "United States",
      address: "4350 Heights Ave",
      detail: "Pittsburg CA",
      detail2: "94565",
      email: "united.states@anindya.biz",
      phone: "",
    },
  ],
  coal_n_mines_contact: [
    {
      name: "Eddy Kristianto",
      email: "eddy.kristianto@anindya.biz",
      phone: "+62 811-1849-320",
    },
    {
      name: "Herry Fajar",
      email: "herry.fajar@anindya.biz",
      phone: "+62 811-9222-216",
    },
    {
      name: "Monang Jaya",
      email: "monang@anindya.biz",
      phone: "+62 811-805-169",
    },
  ],
  import_verif_contact: [
    {
      name: "Adhy Herlastadhy",
      email: "adhy_lsi@anindya.biz",
      phone: "+62 812-5954-8903",
    },
    {
      name: "Intan Rusli",
      email: "intan.rusli_lsi@anindya.biz",
      phone: "+62 813-1331-1168",
    },
  ],

  benefit_tkdn_list: [
    "Increase Domestic Industrial Sector",
    "Increase Small Industry",
    "Create Job Opportunities",
    "Increase Tax Revenue",
    "Increase Other Economic Sectors",
    "Create Investment Opportunities",
  ],

  statutory_list: [
    {
      tab_title: "Government Laws and Regulations",
      documents: [
        {
          name: "Undang-Undang Nomor 3 Tahun 2014 Perindustrian Peraturan Pemerintah Nomor 29 Tahun 2018 Pemberdayaan Industri",
          link: "https://drive.google.com/file/d/1gnUdwaZrOGaj3_ncl-JWQgWDTaVme8lG/view?usp=drive_link",
        },
        {
          name: "Peraturan Pemerintah Nomor 29 Tahun 2018 Pemberdayaan Industri",
          link: "https://drive.google.com/file/d/1ghf7isVjtwiSQt4MD9gYp2-1rqlIz2oe/view",
        },
      ],
    },
    {
      tab_title: "Presidential Regulation, Decree, and Instruction",
      documents: [
        {
          name: "Perpres No. 16/2018 Pengadaan Barang/Jasa Pemerintah",
          link: "https://drive.google.com/file/d/1ggjeTgDqZWW_Hu9B_gpBgFIN_OTeUeEh/view",
        },
        {
          name: "Keppres No. 24/2018 Tim Nasional Peningkatan Penggunaan Produksi Dalam Negeri",
          link: "https://drive.google.com/file/d/1hlw5yhp8n0IoGAc59vSVvlibMbR9PL8u/view",
        },
        {
          name: "Inpres No. 6/2016 Tentang Percepatan Pengembangan Industri Farmasi Dan Alat Kesehatan",
          link: "https://drive.google.com/file/d/1hlAkMysqMCsvJtxNzd4cXpHyeh3ICqGx/view",
        },
        {
          name: "Inpres No. 2/2009 Penggunaan Produk Dalam Negeri dalam Pengadaan Barang/Jasa Pemerintah",
          link: "https://drive.google.com/file/d/1hh1fo-gRKLy6nqcLqvTVHLd3rxvvLsRh/view",
        },
      ],
    },
    {
      tab_title: "Ministerial Regulation, Decree, and Circular Letter",
      documents: [
        {
          name: "Permen Perindustrian No. 02/2014 Pedoman Peningkatan Penggunaan Produk Dalam Negeri Dalam Pengadaan Barang/Jasa Pemerintah",
          link: "https://drive.google.com/file/d/1hdu65F7gZ-4dDXYneNYEBfy76sDMp7Eg/view",
        },
        {
          name: "Permen Perindustrian No. 03/2014 Pedoman Peningkatan Penggunaan Produk Dalam Negeri Dalam Pengadaan Barang/Jasa Pemerintah Yang Tidak Dibiayai Dari Anggaran Pendapatan dan Belanja Negara/Anggaran Pendapatan dan Belanja Daerah",
          link: "https://drive.google.com/file/d/1hcivevrhhd0Y43JeUGaCER5o_oFXEnme/view",
        },
        {
          name: "Permen Perindustrian No. 31/2017 Perubahan Ketiga Atas Peraturan Menteri Perindustrian Nomor 19/M-IND/PER/2/2010 Tentang Daftar Mesin, Barang, Dan Bahan Produksi Dalam Negeri Untuk Pembangunan Atau Pengembangan Industri Dalam Rangka Penanaman Modal.",
          link: "https://drive.google.com/file/d/1hY7jTNy57Osu4fMpHoOTnwGMgZ4kc-mI/view",
        },
        {
          name: "Permen ESDM No. 15/2013 Tentang Penggunaan Produk Dalam Negeri Pada Kegiatan Usaha Hulu Migas",
          link: "https://drive.google.com/file/d/1hOPRpsKLY3hpkFlM9oX7apxcYUbWhABZ/view",
        },
        {
          name: "Permen Kominfo No. 27/2015 Persyaratan Teknis Alat dan/atau Perangkat Perangkat Telekomunikasi Berbasis Standar Teknologi Long Term Evolution",
          link: "https://drive.google.com/file/d/1hIGy9D5LV3LXIMqd4ImjP2zK_DWBEncr/view",
        },
        {
          name: "Permen Kominfo No. 4/2019 Persyaratan Teknis Alat dan/atau Perangkat Telekomunikasi Untuk Keperluan Penyelenggaraan Televisi Siaran dan Radio Siaran",
          link: "https://drive.google.com/file/d/1hH8wjNlZMt2-jTaJUjN6ma9esnsO7xSH/view",
        },
        {
          name: "Permen Kominfo No. 12/2019 Tata Cara Penilaian Pencapaian Tingkat Komponen Dalam Negeri Belanja Modal Dan Belanja Operasional Pada Penyelenggaraan Telekomunikasi",
          link: "https://drive.google.com/file/d/1h8rLZlUwWpawYoSuyK8YnZjSJfZYW6jx/view?usp=share_link",
        },
        {
          name: "Permen BUMN No. PER - 08/MBU/12/2019 Pedoman Umum Pelaksanaan Pengadaan Barang dan Jasa Badan Usaha Milik Negara (BUMN)",
          link: "https://drive.google.com/file/d/1h1F66Il3Pjel9GYVMsfuf4oJwNfYsj14/view?usp=drive_link",
        },
        {
          name: "Kep Menko Bidang Kemaritiman No. 84/2019 Kelompok Kerja Tim Nasional Peningkatan Penggunaan Produk Dalam Negeri",
          link: "https://drive.google.com/file/d/1gyiyR7fJYgdqZynrD1CvemVmU93lLqdI/view",
        },
        {
          name: "SE Menteri BUMN Nomor SE- 02/MBU/2006 Penggunaan Produk Dalam Negeri Dalam Pengadaan Barang/Jasa BUMN Dan Anak Perusahaan BUMN: “Melaporkan Penggunaan Produk Dalam Negeri Kepada Kementerian Negara BUMN Sebagai Wakil Ketua Tim Pelaksana Penggunaan Produk Dalam Negeri & Sebagai KPI Masing-Masing BUMN",
          link: "https://drive.google.com/file/d/1gwFDlerb76LI9IkWCzLZ4hyvFKW3xTb5/view",
        },
      ],
    },
  ],
  content_assessment_list: [
    "Agricultural Supporting Materials",
    "Factory Machinery and Equipment",
    "Electrical Equipment",
    "Computers and Office Equipment",
    "Agricultural Machinery and Equipment",
    "Building Construction Materials",
    "Telecommunications Equipment",
    "Clothing and Work Equipment",
    "Mining Machinery and Equipment",
    "Metal and Metal Items",
    "Transportation",
    "Sports and Education Equipment",
    "Oil and Gas Machinery and Equipment",
    "Chemicals and Chemical Goods",
    "Materials and Health Equipment",
    "Defense Tools",
    "Heavy Equipment, Construction and Material Handling",
    "Electronic Equipment",
    "Laboratory Equipment",
    "Other Goods",
  ],

  faq_list: [
    {
      title: "WHAT ARE THE PURPOSES OF TKDN?",
      desc: "To fulfill the document requirements for the procurement of goods/services in government agencies and the main requirements for registering an E-Catalog. In addition, TKDN is used as a mandatory requirement for production in the cellular telephone, tablet and television set industries.",
    },
    {
      title: "WHAT ARE THE TERMS OF SUBMISSION OF TKDN?",
      desc: "Company Requirements:\n1.Invest in Indonesia\n2.Located in Indonesia\n3.Producing in Indonesia\n\nProduct Terms:\nMaterials & Labor\nProduction Process (change in function)\nDomestic Product (has a higher product value)",
    },
    {
      title: "WHAT IS SIINAS?",
      desc: "The National Industrial Information System (SIINas) is a mechanism to simplify and speed up the process of submitting data originating from the business world to the government. The following is the link to SIINas: https://siinas.kemenperin.go.id/",
    },
    {
      title: "WHAT IS TKDN PRICE PREFERENCE?",
      desc: "Price preference is the price adjustment value to the bid price in the final evaluation process in the procurement of goods/services. Price preference is an incentive for domestic products in the selection of Providers in the form of acceptable excess prices.",
    },
    {
      title: "WHAT IS COMPANY BENEFIT WEIGHT (BMP)?",
      desc: "Company Benefit Weight (BMP) is the value of the award given to Industrial Companies investing and producing in Indonesia. BMP is calculated based on the accumulated weight of the determining factor multiplied by the maximum weight, with a maximum total value of 15%.",
    },
    {
      title: "HOW MUCH DOES TKDN VERIFICATION COST?",
      desc: "TKDN verification costs depend on the product kind, product type, product specifications and location of the production process to be verified. For more information, please contact Marketing PT. Anindya Wiraputra Konsult.",
    },
    {
      title: "HOW LONG DOES THE TKDN VERIFICATION PROCESS CAN BE DONE?",
      desc: "In accordance to Regulation of the Minister of Industry No. 43 of 2022 Article 6 paragraph 2 Calculation and verification of TKDN values and BMP values is carried out within a maximum period of 22 (twenty two) working days counted from received complete and correct application documents.",
    },
    {
      title: "WHAT DOCUMENTS ARE REQUIRED FOR TKDN VERIFICATION?",
      desc: "Documents needed include Legality, Information on Product Raw Materials, Labor, and Working Tools. For more information, please contact Marketing PT. Anindya Wiraputra Konsult.",
    },
    {
      title: "HOW LONG IS THE TKDN AND BMP CERTIFICATE VALID?",
      desc: "Goods TKDN and BMP Certificate valid for 3 years.\nFor Pharmacy TKDN Certificate valid for 2 years.",
    },
    {
      title:
        "WHAT IS THE PROCEDURE FOR EXTENSION OF TKDN CERTIFICATE IF THE VALIDITY PERIOD HAS EXPIRED?",
      desc: "The certificate renewal procedure is the same as when it was originally submitted. Companies can contact PT. Anindya Wiraputra Konsult who has been appointed as an Independent Verification Agency.",
    },
  ],

  our_services:
    "Providing quality and quantity superintending service for coal and minerals industry, the oil and gas sector as well as marine surveys",
  service_val: [
    {
      name: "VPTI",
      subtitle:
        "Indonesia’s Ministry of Trade has mandated that a wide range of goods be inspected prior to export to the country to demonstrate compliance with various national regulations. To meet the Indonesian market requirements our technical verification services identify the eligibility of the goods to be imported into Indonesia as well as the quantity, tariff classification and Ministry of Trade specifications. The issuance of the ANINDYA report known as a Laporan Surveyor or Surveyor’s Report facilitates international trade, reduces the risk of delays in Customs clearance and reduces potential losses from the import of non-compliant goods.",
      col1: [
        {
          title: "Our Services",
          val: [
            {
              sub_val: "Import Verification",
              val: [
                {
                  item: "Iron & Steel",
                  sub_item: [
                    "Steel Iron",
                    "Alloy Steel",
                    "Steel Product Derivatives",
                  ],
                },
                {
                  item: "Specific Products",
                  sub_item: [
                    "Food and Drink",
                    "Toys",
                    "Footwear",
                    "Garment",
                    "Cosmetics",
                    "Electronics",
                    "Traditional Medicine",
                  ],
                },
                {
                  item: "Tires",
                  sub_item: [],
                },
                {
                  item: "Cell Phones, Handheld Computers, Tablet Computers",
                  sub_item: [],
                },
                {
                  item: "Color Multipurpose Machines, Color Photocopy Machines, Color Printers",
                  sub_item: [],
                },
                {
                  item: "Cooling System Based Goods",
                  sub_item: [],
                },
                {
                  item: "Footwear, Electronics, Bicycles & Tricycles",
                  sub_item: [],
                },
                {
                  item: "Secondhand Capital Goods",
                  sub_item: [],
                },
                {
                  item: "Ceramics",
                  sub_item: [],
                },
                {
                  item: "Horticulture",
                  sub_item: [],
                },
                {
                  item: "Precursor",
                  sub_item: [],
                },
                {
                  item: "Apparel and Apparel Accessories",
                  sub_item: [],
                },
                {
                  item: "Garlic",
                  sub_item: [],
                },
                {
                  item: "Downstream Product of Plastic",
                  sub_item: [],
                },
                {
                  item: "Glass Sheet",
                  sub_item: [],
                },
                {
                  item: "Textile and Textile Product (TPT)",
                  sub_item: [],
                },
                {
                  item: "Bag",
                  sub_item: [],
                },
              ],
            },
          ],
        },
      ],
      col2: [],
      method: [],
      clients: [
        "Sharp",
        "The North Face",
        "Matsuo",
        "Mercedes",
        "Komatsu",
        "Starbucks",
        "Bridgestone",
        "Apple",
        "Samsung",
        "LV",
        "Brompton",
        "Aik Moh",
        "Uniqlo",
        "Hermes",
        "Canon",
        "Hp",
        "Panasonic",
        "Cold Stone",
        "ELC",
        "Kohler",
      ],
    },

    {
      name: "TKDN",
      subtitle:
        "Based on Decree of the Indonesian Ministry of Industry No. 4058/2023, Anindya Wiraputra Konsult has been appointed as Independent Verification Body of Indonesia's Local Content Assessment Verification. In an effort to support government programs to improve domestic industry, Anindya Wiraputra Konsult provides a service in form of Local Content Assessment certification.",
      col1: [
        {
          title: "Local Content Assessment (TKDN) Goods Category",
          val: [
            "Alat Transport",
            "Peralatan Olahraga dan Pendidikan",
            "Mesin dan Peralatan Migas",
            "Bahan Kimia dan Barang Kimia",
            "Bahan dan Peralatan Kesehatan",
            "Sarana Pertahanan",
            "Alat Berat, Konstruksi dan Material Handling",
            "Peralatan Elektronika",
            "Peralatan Laboratorium",
            "Barang Lainnya",
          ],
        },
      ],
      col2: [
        {
          title: "",
          val: [
            "Bahan Penunjang Pertanian",
            "Mesin dan Peralatan Pabrik",
            "Peralatan Kelistrikan",
            "Komputer dan Peralatan Kantor",
            "Mesin dan Peralatan Pertanian",
            "Bahan Bangunan/Konstruksi",
            "Peralatan Telekomunikasi",
            "Pakaian dan Perlengkapan Kerja",
            "Mesin dan Peralatan Pertambangan",
            "Logam dan Barang Logam",
          ],
        },
      ],
      method: [],
      clients: [],
    },
    {
      name: "LVV",
      subtitle:
        "Greenhouse gas validation and/or verification conformity assessment body based on validation and verification activity standards in accordance with SNI ISO/IEC 17029:2019 and SNI ISO 14065:2020",
      col1: [
        {
          title: "Layanan Kami",
          val: [
            {
              sub: "Validasi dan Verifikasi Proyek GRK",
              val: [
                {
                  subtitle: "Validasi Proyek",
                  val: [
                    "02.02.01 Energy Industri (renewable/non-renewable sources)",
                    "02.02.06 Energy Demand (Users)",
                    "02.02.07 Manufacturing Industries",
                  ],
                },
                {
                  subtitle: "Verifikasi Proyek",
                  val: [
                    "01.02.01 Energy Industri (renewable/non-renewable sources)",
                    "01.02.03 Energy Demand (Users)",
                    "01.02.04 Manufacturing Industries",
                  ],
                },
              ],
            },
            {
              sub: "Verifikasi Organisasi GRK",
              val: [
                {
                  subtitle: "",
                  val: [
                    "01.01.01 Power Generation and Electric Power Transaction",
                    "01.01.02 General Manufacturing (physical or chemical transformation of materials or substances into new product)",
                    "01.01.04 Metals Production",
                    "01.01.07 Pulp, Paper, and Print",
                    "01.01.12 Agriculture, Forestry and Other Land Use",
                  ],
                },
              ],
            },
            {
              sub: "Layanan Lainnya",
              val: [
                {
                  subtitle: "Verifikasi LCAM",
                  val: [
                    "03.01 Energy Industri (renewable/non-renewable sources)",
                    "03.03 Energy Demand (Users)",
                    "03.04 Manufacturing Industries",
                  ],
                },
                {
                  subtitle: "Validasi DRAM",
                  val: [
                    "02.01 Energy Industri (renewable/non-renewable sources)",
                    "02.03 Energy Demand (Users)",
                    "02.04 Manufacturing Industries",
                  ],
                },
                {
                  subtitle: "Verifikasi Laporan Emisi",
                  val: ["01.01 Combustion of Fossil Fuels"],
                },
              ],
            },
          ],
        },
      ],
      col2: [],
      method: [],
      clients: [],
    },

    {
      name: "Coal",
      subtitle:
        "The analysis of coal at any point in the production and distribution chain must be done with the utmost attention to accuracy and best practice. All our accredited laboratory facilities and experts offer reliable quality certification for coal inspection and testing.",
      col1: [
        {
          title: "Mine and Geological Service",
          val: [
            "Verification Reports for Government Purposes (LHV & LS)",
            "Load and Discharge Supervision",
            "Draft Survey",
            "Cargo Condition & Readiness",
            "Laboratory Analysis",
            "Stock and Warehouse",
            "Sampling Analysis",
            "Cargo Hold & Cleanliness",
            "Stockpile Inventory Measurement",
            "Pre-Shipment",
            "Bias Testing",
            "Cargo Temperature",
            "Verification Report of Adjustment Cost and Distance Transport Certificate (LHV BP & SAJ)",
            "Quality Control Management System",
            "Core Sampling",
            "Exploration JORC",
            "Pit Sampling",
            "Channel Sampling",
            "Due Diligence",
            "General Survey",
            "Geological Mapping",
            "Feasibility Study Stage",
          ],
        },
      ],
      col2: [
        {
          title: "Laboratory Analysis",
          val: [
            "Total Moisture",
            "Calorific Value",
            "Form of Sulphur",
            "Hardgrove Grindability Index",
            "Size Analysis",
            "Trace Elements (As, Se, Cl, F, P, Hg)",
            "Relative Density",
            "Ash Analysis",
            "Ash Fusion Temperature",
            "Crucible Swelling Number",
            "Light Transmittance",
            "Radioactivity",
            "G Value",
            "Plastometric Index (Y Value & X Value)",
            "Transmittance Value",
            "TML Testing",
            "Mechanical Strength of Coke",
            "Coke Re-Activity",
            "Post-Reaction Strength of Coke",
          ],
        },
      ],
      method: ["astm", "iso", "standards-australia", "gb"],
      clients: [
        "Borneo Indobara",
        "Kideco",
        "Jembayan",
        "Adani",
        "Tamtama",
        "SGE",
        "BSSR",
        "BRE",
        "RLK",
        "Equentia",
        "PKN",
        "ABP",
        "BMB",
        "KJB",
        "Titan",
      ],
    },
    {
      name: "Marine",
      subtitle:
        "Inspections, surveys or examinations of marine vessels to assess, monitor and report on their conditions and the products on them, as well as investigate the nature/cause of damage to both vessel and cargo. ANINDYA operates on a 24/7 basis and has invested in a significant pool of expert surveyors to operationally ready at a short notice. With the backing of experienced technical staff, we can assist customers with expert witnesses or perform duties in any legal, dispute and/or claims cases.",
      col1: [
        {
          title: "Technical Survey",
          val: [
            "Vessel's Condition Survey",
            "Cargo Hold Cleanliness",
            "Bunker Survey",
            "On/Off-hire Survey",
            "Lashing/Securing and Towing Survey",
            "Investigation and Damage Survey Fuel Consumption Test",
            "P&l Pre-entry Condition Survey",
          ],
        },
      ],
      col2: [
        {
          title: "Cargo Survey",
          val: [
            "Tally and Condition Survey",
            "Weighing and Measuring",
            "Stuffing & Unstuffing",
            "Loading & Unloading Survey",
            "Draught Survey",
            "Pre-shipment Survey",
            "Cargo Damage Survey & Sampling",
          ],
        },
      ],
      method: [],
      clients: [
        "SIT",
        "AKR",
        "Alexindo",
        "Duta Marine",
        "MSD",
        "Holcim",
        "Indocement",
        "Lafarge",
        "Victoria",
        "Seapol",
        "SLM",
        "SBA",
        "SBI",
        "Trubaindo",
        "HTK",
        "Cotecna",
        "DBS",
        "Watama",
        "Figar",
      ],
    },
    {
      name: "Oil & Gas",
      subtitle:
        "ANINDYA offers a wide range of inspection, survey, testing, control, calibration and other special services to the oil and gas industry. ANINDYA has a team of highly skilled inspectors, surveyors, chemists and technicians who can provide quality and timely services to your requirement.",
      col1: [
        {
          title: "Technical Survey",
          val: [
            "Petroleum & crude oil inspection services",
            "Quality testing for crude oil, petroleum, gas and chemicals",
            "Expediting services",
            "Blending expertise",
            "Cargo treatment",
            "Collateral management & warehousing",
            "Used oil and lubricant condition monitoring",
          ],
        },
      ],
      col2: [],
      method: [],
      clients: ["DCG", "BNP", "PC", "RBN", "DSI", "ABS", "FJS"],
    },
    {
      name: "Agriculture & Food",
      subtitle:
        "Agriculture and food are arguably the world's most important industries. Consumers want assurance of safety and quality at every stage of the process. We operate at every stage in the agricultural product journey and/or claims cases.",
      col1: [
        {
          title: "Our Services",
          val: [
            "Cleanliness inspections",
            "Collateral management and warehousing",
            "Fumigation",
            "Weighing and sampling",
            "Supervision of loading and discharge",
            "Testing and analysis",
          ],
        },
      ],
      col2: [],
      method: [],
      clients: [],
    },
    {
      name: "Mineral & Water",
      subtitle:
        "From mine site exploration to export shipment, ANINDYA provides minerals testing, inspection, assurance and certification services to support mining activities around the world. Whichever form your materials take, whether mined, processed or manufactured, ANINDYA can certify their true intrinsic value via our independent inspection, sampling and testing. We have highly trained and experienced surveyors globally to intervene at major ports, mining sites, smelting and refining facilities. This is further supported by an independent network of testing laboratories.",
      col1: [
        {
          title: "Our Services",
          val: [
            "Mineral Export Verification (LS)",
            "Stockpile Survey",
            "Stock and Warehouse Audits",
            "Sampling",
            "Loading and Discharging Survey",
            "Draft Surveys",
            "Precision Weighing by Weighbridge or High Precision Balances",
            "Representative Sampling",
            "Supervision of Loading and Discharge",
            "Vessel Hatch/Hold Inspection",
            "Third Party and Umpire Testing",
          ],
        },
      ],
      col2: [
        {
          title: "Laboratory Analysis",
          val: [
            "Nickel Ore",
            "Iron Ore",
            "Bauxite",
            "Manganese Ore",
            "Galena Ore",
            "Zinc Concentrate",
            "Lead Concentrate",
            "Tin Ore",
            "Chrome Ore",
            "Copper Concentrate",
          ],
        },
      ],
      method: ["astm", "iso", "gb", "jis"],
      clients: [
        "VDNI",
        "SMI",
        "BSI",
        "YNI",
        "YII",
        "YII-2",
        "GAG",
        "Ranger",
        "Walsin",
        "Hengjaya",
        "TSI",
        "Ifishco",
        "CLM",
      ],
    },
    {
      name: "Non-Metallic Minerals",
      subtitle:
        "Introducing the new division in ANINDYA, in responding to the country's rapid infrastructure development activities.",
      col1: [
        {
          title: "Our Services",
          val: [
            "Load and Discharge Supervision",
            "Draft Survey",
            "Pit Sampling and Analysis",
            "Stockpile Sampling and Analysis",
            "Barge Sampling and Analysis",
            "Vessel Sampling and Analysis",
            "Rail Sampling and Analysis",
            "Cargo Condition",
          ],
        },
      ],
      col2: [
        {
          title: "Laboratory Testing Parameters",
          val: [
            {
              sub: "Crushed Stone for Construction",
              val_sub: [
                "Size Analysis",
                "Clay Lumps and Friable Particles Content",
                "Elongated or Flat Particle",
                "Sulfide and Sulfate  Content",
                "Chloride Content",
              ],
            },
            {
              sub: "Sand for Construction",
              val_sub: [
                "Size Analysis",
                "Stone Powder Content",
                "Clay Lumps and Friable Particles Content",
                "Elongated or Flat Particle",
                "Organic Content",
                "Sulfide and Sulfate Content",
                "Chloride Content",
                "Mica Content",
                "Soundness",
                "Crushing Indicator",
                "Apparent Density",
                "Stacked Void Ratio",
                "Moisture Content",
                "Bulk Density",
              ],
            },
          ],
        },
      ],
      method: [],
      clients: [],
    },
  ],
  vvb_header: "Verification Validation Bodies",
  sub_vvb:
    "Greenhouse gas validation and/or verification conformity assessment body based on validation and verification activity standards in accordance with SNI ISO/IEC 17029:2019 and SNI ISO 14065:2020",
  about_vvb: "About VVB",
  desc: "LVV ensures accurate validation and verification of GHG emissions, aiding global climate change efforts. For organizations pursuing net- zero goals and sustainable development, LVV is an essential part of emissions management.",
  button_about: "Profil LVV",
  lvv_link:
    "https://drive.google.com/file/d/1WTy1YdlmNcYva8f5CbTmiIqgNTgPTshv/preview",
  title_lvv_benefit: "Benefits of GHG Verification Validation",
  benefit_lvv: [
    "Transparency and Accountability",
    "Access to Green Finance",
    "Government Incentives",
    "Cost Savings",
  ],
  title_service_lvv: "Layanan Kami",
  services_desc: [
    {
      title: "GHG Validation and Verification for Project Level",
      desc: "Voluntary Scheme for implementation of Validation and Verification at project level. Referring to National Registry System (SRN PPI) Joint Crediting Mechanism (JCM), Clean Development Mechanism (CDM).",
      scope: {
        title: "Scope of Work",
        list: [
          {
            title: "Project Validation",
            list: [
              "02.02.01 Energy Industri (renewable/non-renewable sources)",
              "02.02.06 Energy Demand (Users)",
              "02.02.07 Manufacturing Industries",
            ],
          },
          {
            title: "Project Verification",
            list: [
              "01.02.01 Energy Industri (renewable/non-renewable sources)",
              "01.02.03 Energy Demand (Users)",
              "01.02.04 Manufacturing Industries",
            ],
          },
        ],
      },
    },
    {
      title: "GHG Verification for Organization Level",
      desc: "Voluntary Scheme for implementation of Verification at organization level. Referring to National Registry System (SRN PPI) Joint Crediting Mechanism (JCM), Clean Development Mechanism (CDM).",
      scope: {
        title: "Scope of Work",
        list: [
          {
            title: "",
            list: [
              "01.01.01 Power Generation and Electric Power Transaction",
              "01.01.02 General Manufacturing (physical or chemical transformation of materials or substances into new product) 01.01.04 Metals Production",
              "01.01.07 Pulp, Paper, and Print",
              "01.01.12 Agriculture, Forestry and Other Land Use",
            ],
          },
        ],
      },
    },
    {
      title: "Other Services",
      desc: "We are currently in the process of applying for the Carbon Economic Value scheme accreditation. The scope we are applying for is as follows:",
      scope: {
        title: "Scope of Work",
        list: [
          {
            title: "Emission Report Verification",
            list: ["01.01 Combustion of Fossil Fuels"],
          },
          {
            title: "DRAM Validation",
            list: [
              "02.01 Energy Industri (renewable/non-renewable sources)",
              "02.03 Energy Demand (Users)",
              "02.04 Manufacturing Industries",
            ],
          },
          {
            title: "LCAM Verification",
            list: [
              "03.01 Energy Industri (renewable/non-renewable sources)",
              "03.03 Energy Demand (Users)",
              "03.04 Manufacturing Industries",
            ],
          },
        ],
      },
    },
    {
      title: "Service Methodology",
      desc: "Our service methodology is based on internationally recognized standards to ensure accuracy, credibility, and sustainability.",
      scope: {
        title: "Scope of Work",
        list: [
          {
            title: "",
            list: [
              "Intergovernmental Panel on Climate Change (IPCC) 2006",
              "Intergovernmental Panel on Climate Change (IPCC) 2019",
              "SNI ISO 14064-1:2018",
              "Joint Crediting Mechanism (JCM)",
              "Clean Development Mechanism (CDM).",
              "GOLD Standar",
              "Verra",
              "SRN PPI",
            ],
          },
        ],
      },
    },
  ],
  handling: [
    {
      title: "Appeal Handling",
      desc: "Banding adalah permohonan oleh personel atau organisasi agar meninjau kembali keputusan hasil validasi/verifikasi yang telah ditetapkan.",
    },
    {
      title: "Complaint Handling",
      desc: "Keluhan adalah pernyataan ketidakpuasan, oleh setiap personel atau pelanggan ataupun pihak yang berkepentingan.",
    },
  ],
};

export default data;
