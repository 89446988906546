import React from "react";
import "./FlowService.css";
import { useTranslation } from "react-i18next";
import { FlowImage } from "../../FlowImage";

function FlowService() {
  const { t } = useTranslation();

  return (
    <>
      <div className="tkdn-container-definition">
        <div className="tkdn-section-header">
          <div className="border-title-vpti" />
          <h1 className="tkdn-section-title" id="vvb-service">
            {t("service_flow_title")}
          </h1>
        </div>
        <FlowImage
          mobile_file_src={`/images/LVV/flow-vvb-mobile`}
          desktop_file_src={`/images/LVV/flow-vvb`}
        />
      </div>
    </>
  );
}
export default FlowService;
